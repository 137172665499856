import { FunctionComponent } from 'react'
import { makeStyles } from '@mui/styles'

import Font from './Northwell-Regular.woff2'

const northwellRegular: any = {
    fontDisplay: 'swap',
    fontFamily: 'Northwell-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    src: [`local('Northwell')`, `local('Northwell-Regular')`, `url('.${Font}') format('woff2')`],
}

const useStyles = makeStyles({
    '@global': {
        '@font-face': northwellRegular,
    },
})

const NorthwellRegular: FunctionComponent = (): JSX.Element => {
    useStyles()

    return <></>
}

export { northwellRegular, NorthwellRegular }
