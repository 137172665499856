// Third-party
import axios from 'axios'
// Internal
import { ProxyUrl } from 'assets/utils/api-data'
import { SearchResult } from 'assets/@types/nominatim'
import { generateQueryString } from 'assets/utils/query-string'

type NominatimServiceParams = {
    q: string
}

type NominatimService = {
    search: ({ q }: NominatimServiceParams) => Promise<any[] | void>
}

const nominatimService = (): NominatimService => {
    const baseUrl = 'https://nominatim.openstreetmap.org/search'

    const search: NominatimService['search'] = async ({ q }): Promise<SearchResult[] | void> => {
        const queryString = generateQueryString({
            addressdetails: 1,
            countrycodes: 'us',
            dedupe: 1,
            extratags: 1,
            namedetails: 1,
            format: 'jsonv2',
            q,
        })
        const url = `${ProxyUrl()}${baseUrl}${queryString}`

        const results = axios
            .get<SearchResult[]>(url)
            .then(({ data }) => data)
            .catch((error) => console.error('nominatimService.search error: ', error))

        return results
    }

    return {
        search,
    }
}

export default nominatimService()
