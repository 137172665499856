import { FunctionComponent } from 'react'
import { Link as RouterLink, Outlet } from 'react-router-dom'
// MUI
import { styled } from '@mui/material/styles'
// Internal
import Logo from 'components/logo'

const Header = styled('header')(({ theme }) => ({
    top: 0,
    left: 0,
    lineHeight: 0,
    width: '100%',
    position: 'absolute',
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5, 5, 0),
    },
}))

const LogoOnlyLayout: FunctionComponent = () => (
    <>
        <Header>
            <RouterLink to="/">
                <Logo />
            </RouterLink>
        </Header>
        <Outlet />
    </>
)

export default LogoOnlyLayout
