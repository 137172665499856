import { FunctionComponent } from 'react'
// MUI
import { Box, BoxProps } from '@mui/material'

interface SvgIconStyleProps extends BoxProps {
    src: string
}

const SvgIconStyle: FunctionComponent<SvgIconStyleProps> = ({
    src,
    color = 'inherit',
    sx,
}): JSX.Element => (
    <Box
        component="span"
        sx={{
            width: 24,
            height: 24,
            mask: `url(${src}) no-repeat center / contain`,
            WebkitMask: `url(${src}) no-repeat center / contain`,
            bgcolor: `${color}.main`,
            ...(color === 'inherit' && { bgcolor: 'currentColor' }),
            ...(color === 'action' && { bgcolor: 'action.active' }),
            ...(color === 'disabled' && { bgcolor: 'action.disabled' }),
            ...(color === 'paper' && { bgcolor: 'background.paper' }),
            ...sx,
        }}
    />
)

export default SvgIconStyle
