import { FunctionComponent } from 'react'
// MUI
import { TextField, Alert, Stack } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// Third-party
import { Form, FormikProvider, useFormik } from 'formik'
import * as Yup from 'yup'
// Internal
import useAuth from 'assets/hooks/use-auth'
import useIsMountedRef from 'assets/hooks/use-is-mounted-ref'

type InitialValues = {
    email: string
    afterSubmit?: string
}

type ResetPasswordFormProps = {
    onSent: VoidFunction
    onGetEmail: (value: string) => void
}

const ResetPasswordForm: FunctionComponent<ResetPasswordFormProps> = ({
    onSent,
    onGetEmail,
}): JSX.Element => {
    // Hooks
    const { resetPassword } = useAuth()
    const isMountedRef = useIsMountedRef()

    const formik = useFormik<InitialValues>({
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('Email must be a valid email address')
                .required('Email is required'),
        }),
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                resetPassword?.(values.email)
                if (isMountedRef.current) {
                    onSent()
                    onGetEmail(formik.values.email)
                    setSubmitting(false)
                }
            } catch (error) {
                console.error(error)
                if (isMountedRef.current) {
                    // TODO: Fix error type
                    setErrors({ afterSubmit: (error as { message: string }).message })
                    setSubmitting(false)
                }
            }
        },
    })

    const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                    <TextField
                        fullWidth
                        {...getFieldProps('email')}
                        type="email"
                        label="Email address"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Reset Password
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    )
}

export default ResetPasswordForm
