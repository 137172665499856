import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
// Internal
import { Pod, SitesState } from 'assets/@types/sites'
import { quickFilter } from 'assets/utils/filter'

const initialState: SitesState<Pod> = {
    filters: {},
    fetching: false,
    ref: null,
    selected: null,
    sites: null,
}

export const podsSlice = createSlice({
    name: 'pods',
    initialState,
    reducers: {
        filter: (state, { payload }: PayloadAction<Record<string, any>>) => {
            const { filters, ref } = state

            const filtersRef = JSON.parse(JSON.stringify(filters))

            Object.assign(filtersRef, payload)

            const filtered =
                ref?.filter((site) => {
                    const keys = Object.keys(filtersRef)

                    if (!keys.length) return true

                    return keys.every((key) => {
                        if (filtersRef[key] === null) return true

                        return (site as any)[key] === filtersRef[key]
                    })
                }) || []

            return {
                ...state,
                filters: filtersRef,
                ref,
                sites: filtered,
            }
        },
        search: (state, { payload }: PayloadAction<string>) => {
            const { sites, ...rest } = state

            return {
                sites: payload ? quickFilter(payload, sites || []) : rest.ref,
                ...rest,
            }
        },
        select: (state, { payload }: PayloadAction<Pod | null>) => {
            state.selected = payload
        },
        set: (state, { payload }: PayloadAction<Partial<SitesState<Pod>>>) => ({
            ...state,
            ...payload,
        }),
        sort: (state, { payload }: PayloadAction<number>) => {
            console.log('payload: ', payload)
            const { sites } = state

            const sitesRef: Pod[] = JSON.parse(JSON.stringify(sites))

            const sorted = sitesRef?.sort(({ name: aName }, { name: bName }) =>
                payload > 0
                    ? bName.localeCompare(aName, 'en', {
                          sensitivity: 'base',
                      })
                    : aName.localeCompare(bName, 'en', {
                          sensitivity: 'base',
                      }),
            )

            return {
                ...state,
                sites: sorted,
            }
        },
        update: (state, { payload }: PayloadAction<any>) => {
            const { sites, ref } = state

            let updatedPod = payload.pod || payload

            // !Looks greedy, consider a less costly approach to updating
            const [updatedRef, updatedSites] = [
                JSON.parse(JSON.stringify(ref)) as Pod[],
                JSON.parse(JSON.stringify(sites)) as Pod[],
            ].map(
                (list) =>
                    list
                        .map(({ id, ...pod }) => {
                            if (id === updatedPod.id) {
                                updatedPod = Object.assign(pod, updatedPod)
                                return updatedPod
                            }

                            return { id, ...pod }
                        })
                        .filter(Boolean) as Pod[],
            )

            return {
                ...state,
                ref: updatedRef,
                selected: updatedPod,
                sites: updatedSites,
            }
        },
    },
})

const addPod = createAction('pods/ADD_POD', ({ pod }: { pod: Pod }, onUpdate: () => void) => ({
    payload: {
        onUpdate,
        pod,
    },
}))

const getPods = createAction('pods/GET_PODS', ({ hubID }: { hubID: number }) => ({
    payload: {
        hubID,
    },
}))

const transferPod = createAction(
    'pods/TRANSFER_POD',
    ({ pod }: { pod: Pod }, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            pod,
        },
    }),
)

const updatePod = createAction(
    'pods/UPDATE_POD',
    ({ pod }: { pod: Pod }, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            pod,
        },
    }),
)

const updatePodStatus = createAction(
    'pods/UPDATE_POD_STATUS',
    (pod: Pick<Pod, 'id' | 'status'>, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            pod,
        },
    }),
)

// Action creators are generated for each case reducer function
const { filter, search, select, set, sort, update } = podsSlice.actions

export default podsSlice.reducer

export {
    addPod,
    getPods,
    filter,
    search,
    select,
    set,
    sort,
    transferPod,
    update,
    updatePod,
    updatePodStatus,
}
