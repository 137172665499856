import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux'
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors'
import { createLogger } from 'redux-logger'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage/session'
import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
// Internal
import { createReducer } from './reducer'
import rootSaga from './sagas'

// Middleware
const loggingMiddleware = createLogger({
    predicate: () => process.env.NODE_ENV === 'development',
})

const sagaMiddleware = createSagaMiddleware()
const { run: runSaga } = sagaMiddleware

// Reducers
const persistedReducer = persistReducer(
    {
        key: 'persisted',
        storage,
        blacklist: ['config', 'toolbox', 'search'],
    },
    createReducer(),
)

// Enhancers
const enhancers = [
    createInjectorsEnhancer({
        createReducer,
        runSaga,
    }),
]

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => [
        loggingMiddleware,
        sagaMiddleware,
        ...getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
    ],
})

// Make reducers hot re-loadable
if (module.hot) {
    module.hot.accept('./reducer', () => {
        forceReducerReload(store)
    })
}

runSaga(rootSaga)

const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

const { dispatch } = store

const useSelector = useReduxSelector
const useDispatch = () => useReduxDispatch<AppDispatch>()

export { store, persistor, dispatch, useSelector, useDispatch }
