// Third-party
import axios from 'axios'

// Internal
import { MegaHubUser, User, UserInfo } from 'assets/@types/users'
import {
    ProxyUrl,
    TransferUserRegionUrl,
    UpdateUserUrl,
    UpdateUserStatusUrl,
    UpdateUserTypeUrl,
    UsersUrl,
    getBaseUrl,
} from 'assets/utils/api-data'
import axiosInstance from 'assets/utils/axios'

type UsersServiceParams = {
    user: User
    profile: User
}

type UsersService = {
    createUser: ({ user }: Pick<UsersServiceParams, 'user'>) => Promise<User | void>
    fetchInfo: ({ user }: Pick<UsersServiceParams, 'user'>) => Promise<UserInfo>
    fetchProfile: () => Promise<User>
    fetchUsers: () => Promise<User[] | void>
    transferUser: ({
        id,
        hubID,
        podID,
    }: Pick<User, 'id' | 'hubID' | 'podID'>) => Promise<User | void>
    transferUserRegion: (
        user: Pick<MegaHubUser, 'id' | 'email' | 'megaHubID'>,
    ) => Promise<User | void>
    updateUser: ({ user }: Pick<UsersServiceParams, 'user'>) => Promise<User | void>
    updateUserStatus: ({ id, status }: Pick<User, 'id' | 'status'>) => Promise<User | void>
    updateUserType: ({ id, type }: Pick<User, 'id' | 'type'>) => Promise<User | void>
}

const usersService = () => {
    const createUser: UsersService['createUser'] = async ({ user }) => {
        const url = `${ProxyUrl()}${UsersUrl()}`

        const result = await axios
            .post<User>(url, user)
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.createUser error ${error}`))

        return result
    }

    const fetchInfo: UsersService['fetchInfo'] = async ({ user: { id } }) => {
        const url = `${ProxyUrl()}${getBaseUrl()}portal/users/${id}/info`

        const result = await axios
            .get<[[UserInfo]]>(url)
            .then(({ data }) => {
                const [result] = data
                return result[0]
            })
            .catch((error) => {
                console.error(`usersService.fetchInfo error ${error}`)
                return Object.assign({} as UserInfo)
            })

        return result
    }

    const fetchProfile: UsersService['fetchProfile'] = async () => {
        const url = `${ProxyUrl()}${getBaseUrl()}users/get-profile`
        const result = await axiosInstance
            .get<{ data: User }>(url)
            .then(({ data }) => {
                console.log(data)
                return data
            })
            .catch((error) => {
                console.error(`usersService.fetchProfile error ${error}`)
                return Object.assign({} as UserInfo)
            })

        return result
    }

    const fetchUsers: UsersService['fetchUsers'] = async () => {
        const url = `${ProxyUrl()}${UsersUrl()}`

        const result = await axios
            .get<User[]>(url)
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.fetchUsers error ${error}`))

        return result
    }

    const transferUser: UsersService['transferUser'] = async ({ id, hubID, podID }) => {
        const url = `${ProxyUrl()}${UpdateUserUrl()}`

        const result = await axios
            .put<User>(url, { id, hubID, podID })
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.transferUser error ${error}`))

        return result
    }

    const transferUserRegion: UsersService['transferUserRegion'] = async (user) => {
        const url = `${ProxyUrl()}${TransferUserRegionUrl()}`

        const result = await axios
            .put<User>(url, user)
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.transferUserRegion error ${error}`))

        return result
    }

    const updateUser: UsersService['updateUser'] = async ({ user }) => {
        const url = `${ProxyUrl()}${UpdateUserUrl()}`

        const result = await axios
            .put<User>(url, user)
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.updateUser error ${error}`))

        return result
    }

    const updateUserStatus: UsersService['updateUserStatus'] = async ({ id, status }) => {
        const url = `${ProxyUrl()}${UpdateUserStatusUrl()}`

        const result = await axios
            .put<User>(url, { status, userID: id })
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.updateUserStatus error ${error}`))

        return result
    }

    const updateUserType: UsersService['updateUserType'] = async ({ id, type }) => {
        const url = `${ProxyUrl()}${UpdateUserTypeUrl()}`

        const result = await axios
            .put<User>(url, { type, userID: id })
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.updateUserType error ${error}`))

        return result
    }

    return {
        createUser,
        fetchInfo,
        fetchProfile,
        fetchUsers,
        transferUser,
        transferUserRegion,
        updateUser,
        updateUserStatus,
        updateUserType,
    }
}

export default usersService()
