// Third-party
import axios from 'axios'
// Internal
import { ProxyUrl, ReportsUrl } from 'assets/utils/api-data'

type ReportsService = {
    fetchReports: () => Promise<any | void>
}

const reportsService = (): ReportsService => {
    const fetchReports: ReportsService['fetchReports'] = async () => {
        const url = `${ProxyUrl()}${ReportsUrl()}`

        const result = await axios
            .get<any>(url)
            .then(({ data }) => data)
            .catch((error) => console.error(`reportsService.fetchReports error ${error}`))

        return result
    }

    return {
        fetchReports,
    }
}

export default reportsService()
