import { createAction, createSlice, PayloadAction, createDraftSafeSelector } from '@reduxjs/toolkit'

// Internal
import { StripeSubscription, StripeSubscriptionState } from 'assets/@types/subscriptions'
import { RootState } from 'store'

const initialState: StripeSubscriptionState = {
    loading: false,
    clientSecret: null,
    hubSubscriptionData: null,
}

export const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        set: (state, { payload }: PayloadAction<Partial<StripeSubscriptionState>>) => ({
            ...state,
            ...payload,
        }),
    },
})

const getClientSecret = createAction(
    'subscription/GET_CLIENT_SECRET',
    ({ amount }: { amount: number }) => ({
        payload: {
            amount,
        },
    }),
)

const clearClientSecret = createAction('subscription/CLEAR_CLIENT_SECRET')

const createPaymentSubscription = createAction(
    'subscription/CREATE_PAYMENT_SUBSCRIPTION',
    ({ subscriptionInfo }: { subscriptionInfo: StripeSubscription }) => ({
        payload: {
            subscriptionInfo,
        },
    }),
)

const updatePaymentSubscription = createAction(
    'subscription/UPDATE_PAYMENT_SUBSCRIPTION',
    ({ subscriptionInfo }: { subscriptionInfo: StripeSubscription }, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            subscriptionInfo,
        },
    }),
)

const getSubscriptionData = createAction(
    'subscription/GET_SUBSCRIPTION_DATA',
    ({ email }: { email: string }) => ({
        payload: {
            email,
        },
    }),
)

// Action creators are generated for each case reducer function
const { set } = locationsSlice.actions

export default locationsSlice.reducer

export {
    set,
    getClientSecret,
    clearClientSecret,
    createPaymentSubscription,
    getSubscriptionData,
    updatePaymentSubscription,
}
