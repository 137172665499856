import { createContext, ReactNode, useEffect, useReducer } from 'react'
import { useNavigate } from 'react-router-dom'
import {
    CognitoUser,
    CognitoUserPool,
    AuthenticationDetails,
    CognitoUserSession,
    CognitoUserAttribute,
} from 'amazon-cognito-identity-js'
// Internal
import { ActionMap, AuthState, AWSCognitoContextType } from 'assets/@types/authentication'
import { AuthorizedUser } from 'assets/@types/portal'
import { portalService } from 'assets/services'
import axios from 'axios'
import globals from 'assets/utils/globals'
import { PATH_AUTH } from 'routes/paths'
import { RootState, useDispatch, useSelector } from 'store'
import { getRegion, set } from 'store/slices/regions'
import { getUserProfile, clearProfile } from 'store/slices/users'
import { UserType } from 'assets/@types/users'
import axiosInstance from 'assets/utils/axios'

export const UserPool = new CognitoUserPool({
    UserPoolId: globals.getCognitoPool(),
    ClientId: globals.getCognitoClient(),
})

const initialState: AuthState = {
    isAuthenticated: false,
    isInitialized: false,
    authUsers: [],
    user: null,
}

enum Types {
    auth = 'AUTHENTICATE',
    logout = 'LOGOUT',
    authUsers = 'AUTH_USERS',
}

type AwsAuthPayload = {
    [Types.auth]: {
        isAuthenticated: boolean
        user: Record<string, any> | null
    }
    [Types.logout]: undefined
    [Types.authUsers]: AuthorizedUser[]
}

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>]

const reducer = (state: AuthState, action: AwsActions) => {
    if (action.type === Types.auth) {
        const { isAuthenticated, user } = action.payload

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
        }
    }

    if (action.type === Types.logout) {
        return {
            ...state,
            isAuthenticated: false,
            user: null,
        }
    }

    if (action.type === Types.authUsers) {
        return {
            ...state,
            authUsers: action.payload,
        }
    }

    return state
}

const AuthContext = createContext<AWSCognitoContextType | null>(null)

function AuthProvider({ children }: { children: ReactNode }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const storeDispatch = useDispatch()
    const { regions, selected } = useSelector(getRegion)

    const navigate = useNavigate()
    const { profile } = useSelector((state: RootState) => state.users)

    useEffect(() => {
        if (profile?.type === UserType.Hero) {
            logout()
        }
    }, [profile])

    const getUserAttributes = (currentUser: CognitoUser) =>
        new Promise<Record<string, any>>((resolve, reject) => {
            currentUser.getUserAttributes((err, attributes) => {
                if (err) {
                    reject(err)
                } else {
                    const results: Record<string, any> = {}

                    attributes?.forEach((attribute) => {
                        results[attribute.Name] = attribute.Value
                    })
                    resolve(results)
                }
            })
        })

    const getSession = (test: AuthState) =>
        new Promise<{
            headers: { [key: string]: string | undefined }
            session: CognitoUserSession | null
            user: CognitoUser | null
        }>((resolve, reject) => {
            const user = UserPool.getCurrentUser()
            if (user) {
                user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
                    if (err) {
                        reject(err)
                    } else {
                        const attributes = await getUserAttributes(user)
                        const token = session?.getAccessToken().getJwtToken() // Use the token or Bearer depend on the wait BE handle, by default amplify API only need to token.
                        axios.defaults.headers.common.Authorization = `${token}`
                        axiosInstance.defaults.headers.common.Authorization = `${token}`
                        axiosInstance.defaults.headers.token = `${session
                            ?.getAccessToken()
                            .getJwtToken()}`
                        storeDispatch(getUserProfile())

                        dispatch({
                            type: Types.auth,
                            payload: { isAuthenticated: true, user: attributes },
                        })

                        storeDispatch(
                            set({
                                regions,
                                selected,
                                user:
                                    test.authUsers.find(
                                        (authUser) => authUser?.email === attributes.email,
                                    ) || null,
                            }),
                        )

                        resolve({
                            user,
                            session,
                            headers: { Authorization: token },
                        })
                    }
                })
            } else {
                dispatch({
                    type: Types.auth,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                })
            }
        })

    const getAuthorizedUsers = async () => {
        try {
            const authorizedUsers = await portalService.fetchAuthorizedUsers()

            if (authorizedUsers) {
                dispatch({
                    type: Types.authUsers,
                    payload: authorizedUsers,
                })

                await getSession({
                    ...state,
                    authUsers: authorizedUsers,
                })
            }
        } catch {
            // TODO: error-handling/reporting
        }
    }

    useEffect(() => {
        try {
            state.authUsers.length === 0 ? getAuthorizedUsers() : getSession(state)
        } catch {
            dispatch({
                type: Types.auth,
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            })
        }
    }, [])

    // We make sure to handle the user update here, but return the resolve value in order for our components to be
    // able to chain additional `.then()` logic. Additionally, we `.catch` the error and "enhance it" by providing
    // a message that our React components can use.
    const login = (email: string, password: string) =>
        new Promise<CognitoUserSession | { message: string }>((resolve, reject) => {
            const user = new CognitoUser({
                Username: email,
                Pool: UserPool,
            })

            const authDetails = new AuthenticationDetails({
                Username: email,
                Password: password,
            })

            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    getSession(state)
                    resolve(data)
                },
                onFailure: (err) => {
                    reject(err)
                },
                newPasswordRequired: () => {
                    // Handle this on login page for update password.
                    resolve({ message: 'newPasswordRequired' })
                },
            })
        })

    // Same thing here
    const logout = () => {
        const user = UserPool.getCurrentUser()
        if (user) {
            user.signOut()
            dispatch({ type: Types.logout })
            storeDispatch(
                set({
                    regions: null,
                    selected: null,
                    user: null,
                }),
            )
            storeDispatch(clearProfile)

            navigate('/')
        }
    }

    const register = (email: string, password: string, firstName: string, lastName: string) =>
        new Promise<undefined>((resolve, reject) => {
            UserPool.signUp(
                email,
                password,
                [
                    new CognitoUserAttribute({ Name: 'email', Value: email }),
                    new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` }),
                ],
                [],
                async (err) => {
                    if (err) {
                        reject(err)
                        return
                    }
                    resolve(undefined)
                    window.location.href = PATH_AUTH.login
                },
            )
        })

    const resetPassword = (email: string) => console.log(email)

    const updateProfile = () => {}

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'cognito',
                user: {
                    displayName: state?.user?.name,
                    role: 'admin',
                    ...state.user,
                },
                getAuthorizedUsers,
                login,
                register,
                logout,
                updateProfile,
                resetPassword,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
