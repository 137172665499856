import { FunctionComponent, useEffect } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
// MUI
import { Box, Link, Stack, Drawer, Tooltip, Typography, CardActionArea } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'
// Internal
import useAuth from 'assets/hooks/use-auth'
import useCollapseDrawer from 'assets/hooks/use-collapse-drawer'
import Logo from 'components/logo'
import MyAvatar from 'components/my-avatar'
import Scrollbar from 'components/scrollbar'
import NavSection from 'components/nav-section'
import { MuiHidden } from 'components/@material-extend'
import { PATH_DASHBOARD } from 'routes/paths'
import sidebarConfig from './sidebar-config'

const DRAWER_WIDTH = 280
const COLLAPSE_WIDTH = 102

const RootStyle = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('lg')]: {
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            duration: theme.transitions.duration.complex,
        }),
    },
}))

const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.shadows[2],
}))

type IconCollapseProps = {
    onToggleCollapse: VoidFunction
    collapseClick: boolean
}

const IconCollapse: FunctionComponent<IconCollapseProps> = ({
    onToggleCollapse,
    collapseClick,
}): JSX.Element => (
    <Tooltip title="Mini Menu">
        <CardActionArea
            onClick={onToggleCollapse}
            sx={{
                width: 18,
                height: 18,
                display: 'flex',
                cursor: 'pointer',
                borderRadius: '50%',
                alignItems: 'center',
                color: 'text.primary',
                justifyContent: 'center',
                border: 'solid 1px currentColor',
                ...(collapseClick && {
                    borderWidth: 2,
                }),
            }}
        >
            <Box
                sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                    transition: (theme) => theme.transitions.create('all'),
                    ...(collapseClick && {
                        width: 0,
                        height: 0,
                    }),
                }}
            />
        </CardActionArea>
    </Tooltip>
)

type DashboardSidebarProps = {
    isOpenSidebar: boolean
    onCloseSidebar: VoidFunction
}

const DashboardSidebar: FunctionComponent<DashboardSidebarProps> = ({
    isOpenSidebar,
    onCloseSidebar,
}): JSX.Element => {
    // Hooks
    const { user } = useAuth()
    const {
        isCollapse,
        collapseClick,
        collapseHover,
        onToggleCollapse,
        onHoverEnter,
        onHoverLeave,
    } = useCollapseDrawer()
    const { pathname } = useLocation()

    useEffect(() => {
        if (isOpenSidebar) {
            onCloseSidebar()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])

    const renderContent = (
        <Scrollbar
            sx={{
                height: 1,
                '& .simplebar-content': {
                    height: 1,
                    display: 'flex',
                    flexDirection: 'column',
                },
            }}
        >
            <Stack
                spacing={3}
                sx={{
                    px: 2.5,
                    pt: 3,
                    pb: 2,
                    ...(isCollapse && {
                        alignItems: 'center',
                    }),
                }}
            >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
                        <Logo />
                    </Box>
                    <MuiHidden width="lgDown">
                        {!isCollapse && (
                            <IconCollapse
                                onToggleCollapse={onToggleCollapse}
                                collapseClick={collapseClick}
                            />
                        )}
                    </MuiHidden>
                </Stack>
                {isCollapse ? (
                    <MyAvatar sx={{ boxShadow: ({ shadows }) => shadows[2], mx: 'auto', mb: 2 }} />
                ) : (
                    <Link underline="none" component={RouterLink} to={'#'}>
                        <AccountStyle>
                            <MyAvatar color="warning" />
                            <Box
                                sx={{
                                    ml: 2,
                                }}
                                style={{ lineBreak: 'anywhere' }}
                            >
                                <Typography
                                    variant="subtitle2"
                                    sx={{
                                        color: ({ palette }) =>
                                            palette.getContrastText(palette.primary.main),
                                    }}
                                >
                                    {user?.displayName || user?.email}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    sx={{
                                        color: ({ palette }) =>
                                            palette.getContrastText(palette.primary.main),
                                    }}
                                >
                                    {user?.role || 'Administrator'}
                                </Typography>
                            </Box>
                        </AccountStyle>
                    </Link>
                )}
            </Stack>
            <NavSection navConfig={sidebarConfig} isShow={!isCollapse} />
            <Box sx={{ flexGrow: 1 }} />
        </Scrollbar>
    )

    return (
        <RootStyle
            sx={{
                width: {
                    lg: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH,
                },
                ...(collapseClick && {
                    position: 'absolute',
                }),
            }}
        >
            <MuiHidden width="lgUp">
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MuiHidden>
            <MuiHidden width="lgDown">
                <Drawer
                    open
                    variant="persistent"
                    onMouseEnter={onHoverEnter}
                    onMouseLeave={onHoverLeave}
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: 'background.default',
                            ...(isCollapse && {
                                width: COLLAPSE_WIDTH,
                            }),
                            ...(collapseHover && {
                                borderRight: 0,
                                backdropFilter: 'blur(6px)',
                                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                                boxShadow: (theme) => theme.customShadows.z20,
                                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88),
                            }),
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            </MuiHidden>
        </RootStyle>
    )
}

export default DashboardSidebar
