import { FunctionComponent } from 'react'
// Internal
import useAuth from 'assets/hooks/use-auth'
import createAvatar from 'assets/utils/createAvatar'
import MuiAvatar, { MuiAvatarProps } from './@material-extend/mui-avatar'

type MyAvatarProps = {
    color?: 'primary' | 'secondary' | 'warning'
} & MuiAvatarProps

const MyAvatar: FunctionComponent<MyAvatarProps> = ({
    color = 'primary',
    ...props
}): JSX.Element => {
    // Hooks
    const { user } = useAuth()

    const { name } = createAvatar(user?.displayName || user?.email)

    return (
        <MuiAvatar
            src={user?.photoURL}
            alt={user?.displayName || user?.email}
            color={color}
            {...props}
        >
            {name}
        </MuiAvatar>
    )
}

export default MyAvatar
