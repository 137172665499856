import { FunctionComponent, useEffect } from 'react'
// emotion
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
// MUI
import { useTheme } from '@mui/material/styles'
// Third-party
import rtlPlugin from 'stylis-plugin-rtl'

const RtlLayout: FunctionComponent = ({ children }): JSX.Element => {
    // Hooks
    const theme = useTheme()

    useEffect(() => {
        document.dir = theme.direction
    }, [theme.direction])

    const cacheRtl = createCache({
        key: theme.direction === 'rtl' ? 'rtl' : 'css',
        // @ts-ignore
        stylisPlugins: theme.direction === 'rtl' ? [rtlPlugin] : [],
    })

    cacheRtl.compat = true

    return <CacheProvider value={cacheRtl}>{children}</CacheProvider>
}

export default RtlLayout
