import { FunctionComponent, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import closeFill from '@iconify/icons-eva/close-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
// MUI
import {
    Alert,
    Box,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    styled,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import { LoadingButton } from '@mui/lab'
// Third-party
import { useFormik, Form, FormikProvider } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
// Internal
import useAuth from 'assets/hooks/use-auth'
import useIsMountedRef from 'assets/hooks/use-is-mounted-ref'
import { MuiIconButton } from '../@material-extend'
import { PATH_AUTH } from 'routes/paths'
import { RootState, useSelector } from 'store'
import { UserType } from 'assets/@types/users'

type InitialValues = {
    email: string
    password: string
    remember: boolean
    afterSubmit?: string
}

const LoginButton = styled(LoadingButton)(({ theme }) => ({
    borderRadius: 32,
}))

const RegisterButton = styled(LoadingButton)(({ theme }) => ({
    backgroundColor: '#404041',
    borderRadius: 32,
}))

const LoginForm: FunctionComponent = (): JSX.Element => {
    // State hook
    const [showPassword, setShowPassword] = useState(false)
    // Hooks
    const { login } = useAuth()
    const isMountedRef = useIsMountedRef()
    const { profile } = useSelector((state: RootState) => state.users)

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const formik = useFormik<InitialValues>({
        initialValues: {
            email: '',
            password: '',
            remember: true,
        },
        validationSchema: Yup.object().shape({
            email: Yup.string()
                .email('Email must be a valid email address')
                .required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                await login(values.email, values.password)

                if (isMountedRef.current) {
                    setSubmitting(false)
                }
            } catch (error) {
                console.error(error)
                resetForm()
                if (isMountedRef.current) {
                    setSubmitting(false)
                    // TODO: Fix error type
                    setErrors({ afterSubmit: (error as { message: string }).message })
                }
            }
        },
    })

    useEffect(() => {
        if (profile !== null) {
            if (profile.type === UserType.Hero) {
                enqueueSnackbar('Please use admin accounts to login.', {
                    variant: 'error',
                    action: (key) => (
                        <MuiIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MuiIconButton>
                    ),
                })
            } else {
                enqueueSnackbar('Login success', {
                    variant: 'success',
                    action: (key) => (
                        <MuiIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MuiIconButton>
                    ),
                })
            }
        }
    }, [profile])

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik

    const handleShowPassword = () => {
        setShowPassword((show) => !show)
    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3} sx={{ mb: 5 }}>
                    {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        placeholder="Email address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        FormHelperTextProps={{
                            sx: { fontFamily: 'Wellingtons-Regular' },
                        }}
                        InputLabelProps={{
                            shrink: false,
                            sx: { fontFamily: 'Wellingtons-Medium' },
                        }}
                        InputProps={{
                            sx: {
                                backgroundColor: (theme) => theme.palette.common.white,
                                fontFamily: 'Wellingtons-Medium',
                            },
                        }}
                        variant="outlined"
                    />
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        {...getFieldProps('password')}
                        FormHelperTextProps={{
                            sx: { fontFamily: 'Wellingtons-Regular' },
                        }}
                        InputLabelProps={{
                            shrink: false,
                            sx: { fontFamily: 'Wellingtons-Medium' },
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                            sx: {
                                backgroundColor: (theme) => theme.palette.common.white,
                                fontFamily: 'Wellingtons-Medium',
                            },
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        variant="outlined"
                    />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 7 }}>
                    <Box display="flex" flex="1" mr={2}>
                        <LoginButton
                            color="secondary"
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            loading={isSubmitting}
                            sx={{ fontFamily: 'Wellingtons-Bold' }}
                        >
                            Login
                        </LoginButton>
                    </Box>
                    <Box display="flex" flex="1" ml={2}>
                        <Tooltip arrow followCursor placement="top" title="Feature coming soon!">
                            <RegisterButton
                                fullWidth
                                size="large"
                                sx={{ fontFamily: 'Wellingtons-Bold' }}
                                variant="contained"
                            >
                                Register
                            </RegisterButton>
                        </Tooltip>
                    </Box>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center" sx={{ mb: 7 }}>
                    <Link
                        component={RouterLink}
                        sx={{
                            color: (theme) => theme.palette.common.white,
                            fontFamily: 'Wellingtons-Medium',
                            fontWeight: 400,
                        }}
                        to={PATH_AUTH.resetPassword}
                        underline="none"
                        variant="subtitle2"
                    >
                        Forgot password?
                    </Link>
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Typography
                        sx={{
                            fontFamily: 'Northwell-Regular',
                            fontSize: 36,
                            color: (theme) => theme.palette.common.white,
                        }}
                    >
                        From Your Neighborhood to the Nations!
                    </Typography>
                </Stack>
            </Form>
        </FormikProvider>
    )
}

export default LoginForm
