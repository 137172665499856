// Internal
import SvgIconStyle from 'components/svg-icon-style'
import { PATH_DASHBOARD } from 'routes/paths'

const getIcon = (name: string): JSX.Element => (
    <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
)

const ICONS = {
    cart: getIcon('ic_cart'),
    user: getIcon('ic_user'),
    reports: getIcon('ic_analytics'),
    sites: getIcon('ic_kanban'),
    dashboard: getIcon('ic_dashboard'),
}

const sidebarConfig = [
    {
        subheader: 'management',
        items: [
            {
                title: 'hubs',
                path: PATH_DASHBOARD.hubs.root,
                icon: ICONS.sites,
            },
            {
                title: 'users',
                path: PATH_DASHBOARD.users.root,
                icon: ICONS.user,
            },
        ],
    },
    {
        subheader: 'administrative',
        items: [
            {
                title: 'reports',
                path: PATH_DASHBOARD.admin.reports,
                icon: ICONS.reports,
            },
        ],
    },
    {
        subheader: 'subscriptions',
        items: [
            {
                title: 'billing',
                path: PATH_DASHBOARD.admin.billing,
                icon: ICONS.reports,
            },
        ],
    },
]

export default sidebarConfig
