import { call, put, select, takeLatest } from '@redux-saga/core/effects'
// Internal
import {
    addUser,
    getUsers,
    getHubUsers,
    getPodUsers,
    set,
    transferUser,
    transferUserRegion,
    update as updateState,
    updateUser,
    updateUserPortalStatus,
    updateUserStatus,
    updateUserType,
    getUserProfile,
    clearProfile,
} from '../slices/users'
import { MegaHubUser, User } from 'assets/@types/users'
import { hubsService, podsService, portalService, usersService } from 'assets/services'
import { Region } from 'assets/@types'
import { getRegion } from 'store/slices/regions'

function* add({ payload }: ReturnType<typeof addUser>) {
    yield put(set({ fetching: true }))

    const { onUpdate, user } = payload
    yield call(usersService.createUser, { user })

    yield call(onUpdate)
}

function* get() {
    const { selected, user }: Region = yield select(getRegion)

    yield put(set({ fetching: true, ref: [], users: [] }))

    let users: User[] = yield call(usersService.fetchUsers)
    users = users.filter(({ hubID, megaHubID }) =>
        selected
            ? megaHubID > 0
                ? megaHubID === selected?.id
                : hubID === selected.id
            : megaHubID === user?.region,
    )

    yield put(set({ fetching: false, ref: users, users }))
}

function* getProfile() {
    yield put(set({ fetching: true, profile: null }))

    let { data } = yield call(usersService.fetchProfile)

    yield put(set({ fetching: false, profile: data }))
}

function* clear() {
    yield put(set({ fetching: true, profile: null }))
}

function* getUsersByHubID({ payload }: ReturnType<typeof getHubUsers>) {
    const { hubID } = payload

    yield put(set({ fetching: true, ref: [], users: [] }))

    const users: User[] = yield call(hubsService.fetchUsers, { hubID })

    yield put(set({ fetching: false, ref: users, users }))
}

function* getUsersByPodID({ payload }: ReturnType<typeof getPodUsers>) {
    const { podID } = payload

    yield put(set({ fetching: true, ref: [], users: [] }))

    const users: User[] = yield call(podsService.fetchUsers, { podID })

    yield put(set({ fetching: false, ref: users, users }))
}

function* transfer({ payload }: ReturnType<typeof transferUser>) {
    yield put(set({ fetching: true }))

    const { onUpdate, user } = payload
    yield call(usersService.transferUser, user)

    yield put(updateState(user))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* transferRegion({ payload }: ReturnType<typeof transferUserRegion>) {
    yield put(set({ fetching: true }))

    const { megaHubID, onUpdate, user } = payload

    const newRegionUser: Pick<MegaHubUser, 'id' | 'email' | 'megaHubID'> = {
        id: user.id,
        email: user.email,
        megaHubID: +megaHubID,
    }

    yield call(usersService.transferUserRegion, newRegionUser)

    yield put(updateState(user))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* update({ payload }: ReturnType<typeof updateUser>) {
    yield put(set({ fetching: true }))

    const { onUpdate, user } = payload
    yield call(usersService.updateUser, { user })

    yield put(updateState(user))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* updatePortalStatus({ payload }: ReturnType<typeof updateUserPortalStatus>) {
    yield put(set({ fetching: true }))

    const { onUpdate, user } = payload
    yield call(portalService.updateAuthorizedUser, {
        user: { id: user.id, email: user.email, region: user.megaHubID },
    })

    yield call(get)
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* updateStatus({ payload }: ReturnType<typeof updateUserStatus>) {
    yield put(set({ fetching: true }))

    const { onUpdate, user } = payload
    yield call(usersService.updateUserStatus, { id: user.id, status: user.status })

    yield put(updateState({ user }))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* updateType({ payload }: ReturnType<typeof updateUserType>) {
    yield put(set({ fetching: true }))

    const { onUpdate, user } = payload
    yield call(usersService.updateUserType, { id: user.id, type: user.type })

    yield put(updateState({ user }))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* usersSaga() {
    yield takeLatest(addUser.type, add)
    yield takeLatest(getUsers.type, get)
    yield takeLatest(getHubUsers.type, getUsersByHubID)
    yield takeLatest(getPodUsers.type, getUsersByPodID)
    yield takeLatest(transferUser.type, transfer)
    yield takeLatest(transferUserRegion.type, transferRegion)
    yield takeLatest(updateUser.type, update)
    yield takeLatest(updateUserPortalStatus.type, updatePortalStatus)
    yield takeLatest(updateUserStatus.type, updateStatus)
    yield takeLatest(updateUserType.type, updateType)
    yield takeLatest(getUserProfile.type, getProfile)
    yield takeLatest(clearProfile.type, clear)
}

export default usersSaga
