import { FunctionComponent } from 'react'
// MUI
import { Popover, PopoverProps } from '@mui/material'
import { alpha, styled } from '@mui/material/styles'

const ArrowStyle: FunctionComponent<{ color?: string }> = styled('span')(({ color, theme }) => ({
    [theme.breakpoints.up('sm')]: {
        top: -7,
        zIndex: 1,
        width: 12,
        right: 20,
        height: 12,
        content: "''",
        position: 'absolute',
        borderRadius: '0 0 4px 0',
        transform: 'rotate(-135deg)',
        background: color || theme.palette.background.paper,
        borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
        borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    },
}))

type MenuPopoverProps = {
    color?: string
} & PopoverProps

const MenuPopover: FunctionComponent<MenuPopoverProps> = ({
    children,
    color,
    sx,
    ...rest
}): JSX.Element => {
    return (
        <Popover
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            PaperProps={{
                sx: {
                    mt: 1.5,
                    ml: 0.5,
                    overflow: 'inherit',
                    backgroundColor: ({ palette }) => color || palette.background.paper,
                    boxShadow: ({ customShadows }) => customShadows.z20,
                    border: ({ palette }) => `solid 1px ${palette.grey[500_8]}`,
                    width: 200,
                    ...sx,
                },
            }}
            {...rest}
        >
            <ArrowStyle color={color} />
            {children}
        </Popover>
    )
}

export default MenuPopover
