// Third-party
import axios from 'axios'
// Internal
import { ProxyUrl, TransferPortalConfigUrl } from 'assets/utils/api-data'
import { HubMetadata, HubMetadataConfig, SiteBaseMetadata } from 'assets/@types/sites'

type ConfigService = {
    fetchTransferPortalConfig: () => Promise<HubMetadataConfig | void>
}

const configService = (): ConfigService => {
    const fetchTransferPortalConfig: ConfigService['fetchTransferPortalConfig'] = async () => {
        const url = `${ProxyUrl()}${TransferPortalConfigUrl()}`

        const results = axios
            .get<HubMetadata[]>(url)
            .then(({ data }) =>
                data.reduce<HubMetadataConfig>((acc, hub) => {
                    if (hub) {
                        const pods: SiteBaseMetadata[] =
                            typeof hub.pods === 'string' ? JSON.parse(hub.pods) : hub.pods

                        const podsConfig =
                            pods?.length > 0
                                ? pods.reduce((acc, pod) => {
                                      if (pod?.id) {
                                          Object.assign(acc, {
                                              [+pod.id]: pod,
                                          })
                                      }

                                      return acc
                                  }, {})
                                : []

                        Object.assign(acc, {
                            [+hub.id]: {
                                ...hub,
                                pods: podsConfig,
                            },
                        })
                    }

                    return acc
                }, {} as HubMetadataConfig),
            )
            .catch((error) => console.error('configService.search error: ', error))

        return results
    }

    return {
        fetchTransferPortalConfig,
    }
}

export default configService()
