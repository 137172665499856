import { FunctionComponent } from 'react'
// MUI
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
// Internal
import useLocales from 'assets/hooks/use-locales'

const ThemeLocalization: FunctionComponent = ({ children }) => {
    // Hooks
    const defaultTheme = useTheme()
    const { currentLang } = useLocales()
    const theme = createTheme(defaultTheme, currentLang.systemValue)

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemeLocalization
