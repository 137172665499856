import { FunctionComponent, useRef, useState } from 'react'
// MUI
import { Box, Button, MenuItem, Typography } from '@mui/material'
// Internal
import MenuPopover from 'components/menu-popover'
import { useDispatch, useSelector } from 'store'
import { set, getRegion } from 'store/slices/regions'
import { Hub } from 'assets/@types/sites'
import { getHubs } from 'store/slices/hubs'
import { getUsers } from 'store/slices/users'

const RegionPopover: FunctionComponent = (): JSX.Element => {
    // State hook
    const [open, setOpen] = useState(false)
    // Redux hooks
    const dispatch = useDispatch()
    const { regions, selected, user } = useSelector(getRegion)
    // Hooks
    const anchorRef = useRef(null)

    const enableRegionsSelect = regions && regions?.length > 0 && user?.region === 4

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const selectRegion = (region: Hub): void => {
        dispatch(
            set({
                regions,
                selected: region,
                user,
            }),
        )

        dispatch(getHubs)
        dispatch(getUsers)

        handleClose()
    }

    return (
        <>
            <Button
                ref={anchorRef}
                onClick={handleOpen}
                style={{ pointerEvents: enableRegionsSelect ? 'auto' : 'none' }}
            >
                <Box sx={{ alignItems: 'flex-end', display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="subtitle1">
                        {selected ? selected.name : 'All Regions'}
                    </Typography>
                    {selected && (
                        <Typography variant="caption">{`${selected.address1}, ${selected.city}, ${selected.state} ${selected.zip}`}</Typography>
                    )}
                </Box>
            </Button>
            {enableRegionsSelect && (
                <MenuPopover
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorRef.current}
                    sx={{ width: 280 }}
                >
                    {regions?.map(({ id, name, ...region }) => (
                        <MenuItem
                            key={id}
                            onClick={() => selectRegion({ id, name, ...region })}
                            selected={id === selected?.id}
                            sx={{ typography: 'body2', py: 1, px: 2.5 }}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </MenuPopover>
            )}
        </>
    )
}

export default RegionPopover
