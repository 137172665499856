import { FunctionComponent, useState } from 'react'
import { Outlet } from 'react-router-dom'
// MUI
import { styled, useTheme } from '@mui/material/styles'
// Internal
import { useCollapseDrawer } from 'assets/hooks'
import DashboardNavbar from './dashboard-navbar'
import DashboardSidebar from './dashboard-sidebar'

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
    display: 'flex',
    minHeight: '100%',
    overflow: 'hidden',
})

const MainStyle = styled('div')(({ theme }) => ({
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '100%',
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
        paddingTop: APP_BAR_DESKTOP + 24,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
}))

const DashboardLayout: FunctionComponent = (): JSX.Element => {
    // State hooks
    const [open, setOpen] = useState(false)
    // Hooks
    const theme = useTheme()
    const { collapseClick } = useCollapseDrawer()

    return (
        <RootStyle>
            <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
            <MainStyle
                sx={{
                    transition: theme.transitions.create('margin', {
                        duration: theme.transitions.duration.complex,
                    }),
                    ...(collapseClick && {
                        ml: '102px',
                    }),
                }}
            >
                <Outlet />
            </MainStyle>
        </RootStyle>
    )
}

export default DashboardLayout
