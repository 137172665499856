import { FunctionComponent, useMemo } from 'react'
// MUI
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles'
// Internal
import useSettings from 'assets/hooks/use-settings'
import componentsOverride from 'assets/theme/overrides'

const ThemePrimaryColor: FunctionComponent = ({ children }): JSX.Element => {
    // Hooks
    const defaultTheme = useTheme()
    const { setColor } = useSettings()

    const themeOptions = useMemo(
        () => ({
            ...defaultTheme,
            palette: {
                ...defaultTheme.palette,
                primary: setColor,
            },
            customShadows: {
                ...defaultTheme.customShadows,
                primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
            },
        }),
        [setColor, defaultTheme],
    )

    const theme = createTheme(themeOptions)
    theme.components = componentsOverride(theme)

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>
}

export default ThemePrimaryColor
