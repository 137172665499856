export type UsersState = {
    filters: Record<string, any>
    fetching: boolean
    ref: User[] | null
    selected: User | null
    users: User[] | null
    profile: User | null
}

export enum UserStatus {
    'Pending approval' = 2,
    'Active' = 3,
    'Inactive' = 4,
}

export enum UserType {
    'Hub admin' = 1,
    'Pod admin' = 2,
    'Hero' = 3,
}

export type UserTypeList = { label: UserType; value: number }[]

export type User = {
    address1: string
    address2: string
    city: string
    email: string
    firstName: string
    hubID: number
    hubName: string
    id: number
    lastName: string
    megaHub: boolean
    megaHubID: number
    phone: string
    podID: number
    podName: string
    portal: boolean
    profileImageUrl: string
    state: string
    status: UserStatus
    type: UserType
    zip: string
}

export type UserInfo = {
    items: number
    families: number
    prayers: number
    salvations: number
}

export type UserMetadata = Partial<Pick<User, 'id' | 'firstName' | 'lastName' | 'profileImageUrl'>>

export type MegaHubUser = {
    megaHub: string
    megaHubID: number
} & PortalUser

export type PortalUser = {
    email: string
    id: number
    megaHubID?: number
    region: number
}
