// i18n
import 'assets/i18n'

// highlight
import 'assets/utils/highlight'

// scroll bar
import 'simplebar/src/simplebar.css'

// map
import 'mapbox-gl/dist/mapbox-gl.css'

// lightbox
import 'react-image-lightbox/style.css'

// editor
import 'react-quill/dist/quill.snow.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

// slick-carousel
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// lazy image
import 'lazysizes'
import 'lazysizes/plugins/attrchange/ls.attrchange'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'
// MUI
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
// Internal
import LoadingScreen from 'components/loading-screen'
import { AuthProvider } from 'contexts/aws-cognito-context'
import { CollapseDrawerProvider } from 'contexts/collapse-drawer-context'
import { SettingsProvider } from 'contexts/settings-context'
import { store, persistor } from 'store'

import App from './app'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

ReactDOM.render(
    <HelmetProvider>
        <ReduxProvider store={store}>
            <PersistGate loading={<LoadingScreen />} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                        <CollapseDrawerProvider>
                            <BrowserRouter>
                                <AuthProvider>
                                    <App />
                                </AuthProvider>
                            </BrowserRouter>
                        </CollapseDrawerProvider>
                    </SettingsProvider>
                </LocalizationProvider>
            </PersistGate>
        </ReduxProvider>
    </HelmetProvider>,
    document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
