import { FunctionComponent, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
// MUI
import { Button, Box, Divider, Typography } from '@mui/material'
import { alpha } from '@mui/material/styles'
// Third-party
import { useSnackbar } from 'notistack'
// Internal
import useAuth from 'assets/hooks/use-auth'
import useIsMountedRef from 'assets/hooks/use-is-mounted-ref'
import { MuiIconButton } from 'components/@material-extend'
import MenuPopover from 'components/menu-popover'
import MyAvatar from 'components/my-avatar'

const AccountPopover: FunctionComponent = (): JSX.Element => {
    // State hook
    const [open, setOpen] = useState(false)
    // Hooks
    const navigate = useNavigate()
    const anchorRef = useRef(null)
    const { user, logout } = useAuth()
    const isMountedRef = useIsMountedRef()
    const { enqueueSnackbar } = useSnackbar()

    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleLogout = async () => {
        try {
            await logout?.()
            if (isMountedRef.current) {
                navigate('/')
                handleClose()
            }
        } catch (error) {
            console.error(error)
            enqueueSnackbar('Unable to logout', { variant: 'error' })
        }
    }

    return (
        <>
            <MuiIconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    padding: 0,
                    width: 44,
                    height: 44,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                        },
                    }),
                }}
            >
                <MyAvatar />
            </MuiIconButton>
            <MenuPopover
                open={open}
                onClose={handleClose}
                anchorEl={anchorRef.current}
                sx={{ width: 220 }}
            >
                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant="subtitle1" noWrap>
                        {user?.displayName}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                        {user?.email}
                    </Typography>
                </Box>
                <Divider sx={{ my: 1 }} />
                <Box sx={{ p: 2, pt: 1.5 }}>
                    <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
                        Logout
                    </Button>
                </Box>
            </MenuPopover>
        </>
    )
}

export default AccountPopover
