import { FunctionComponent } from 'react'
import { makeStyles } from '@mui/styles'
// Internal
import Font from './Wellingtons-Bold.woff2'

const wellingtonsBold: any = {
    fontDisplay: 'swap',
    fontFamily: 'Wellingtons-Bold',
    fontStyle: 'bold',
    fontWeight: 700,
    src: [`local('Wellingtons')`, `local('Wellingtons-Bold')`, `url('.${Font}') format('woff2')`],
}

const useStyles = makeStyles({
    '@global': {
        '@font-face': wellingtonsBold,
    },
})

const WellingtonsBold: FunctionComponent = (): JSX.Element => {
    useStyles()

    return <></>
}

export { wellingtonsBold, WellingtonsBold }
