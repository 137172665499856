const path = (root: string, sublink: string): string => `${root}${sublink}`

const ROOTS_AUTH = '/auth'
const ROOTS_DASHBOARD = '/'

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    register: path(ROOTS_AUTH, '/register'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
}

export const PATH_PAGE = {
    maintenance: '/maintenance',
    page404: '/404',
    page500: '/500',
}

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    admin: {
        root: path(ROOTS_DASHBOARD, '/admin'),
        reports: path(ROOTS_DASHBOARD, '/admin/reports'),
        billing: path(ROOTS_DASHBOARD, '/admin/billing'),
    },
    users: {
        root: path(ROOTS_DASHBOARD, '/users'),
        view: path(ROOTS_DASHBOARD, '/users/:userID'),
        create: path(ROOTS_DASHBOARD, '/users/create'),
    },
    hubs: {
        root: path(ROOTS_DASHBOARD, '/hubs'),
        viewHub: path(ROOTS_DASHBOARD, '/hubs/:hubId'),
        pods: path(ROOTS_DASHBOARD, '/hubs/:hubId/pods'),
        viewPod: path(ROOTS_DASHBOARD, '/hubs/:hubId/pods/:podId'),
        create: path(ROOTS_DASHBOARD, '/hubs/create'),
    },
}
