import { FunctionComponent, useState } from 'react'
import { Icon } from '@iconify/react'
import eyeFill from '@iconify/icons-eva/eye-fill'
import closeFill from '@iconify/icons-eva/close-fill'
import eyeOffFill from '@iconify/icons-eva/eye-off-fill'
// MUI
import { Stack, Alert, TextField, IconButton, InputAdornment } from '@mui/material'
import { LoadingButton } from '@mui/lab'
// Third-party
import { useFormik, Form, FormikProvider } from 'formik'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'
// Internal
import useAuth from 'assets/hooks/use-auth'
import useIsMountedRef from 'assets/hooks/use-is-mounted-ref'
import { MuiIconButton } from '../@material-extend'

type InitialValues = {
    email: string
    password: string
    firstName: string
    lastName: string
    afterSubmit?: string
}

const RegisterForm: FunctionComponent = (): JSX.Element => {
    // State hook
    const [showPassword, setShowPassword] = useState(false)
    // Hooks
    const { register } = useAuth()
    const isMountedRef = useIsMountedRef()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const formik = useFormik<InitialValues>({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('First name required'),
            lastName: Yup.string()
                .min(2, 'Too Short!')
                .max(50, 'Too Long!')
                .required('Last name required'),
            email: Yup.string()
                .email('Email must be a valid email address')
                .required('Email is required'),
            password: Yup.string().required('Password is required'),
        }),
        onSubmit: async (values, { setErrors, setSubmitting }) => {
            try {
                // await register(values.email, values.password, values.firstName, values.lastName)
                // enqueueSnackbar('Register success', {
                //     variant: 'success',
                enqueueSnackbar('Feature coming soon!', {
                    variant: 'info',
                    action: (key) => (
                        <MuiIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MuiIconButton>
                    ),
                })
                if (isMountedRef.current) {
                    setSubmitting(false)
                }
            } catch (error) {
                console.error(error)
                if (isMountedRef.current) {
                    // TODO: Fix error type
                    setErrors({ afterSubmit: (error as { message: string }).message })
                    setSubmitting(false)
                }
            }
        },
    })

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                        <TextField
                            fullWidth
                            label="First name"
                            {...getFieldProps('firstName')}
                            error={Boolean(touched.firstName && errors.firstName)}
                            helperText={touched.firstName && errors.firstName}
                        />
                        <TextField
                            fullWidth
                            label="Last name"
                            {...getFieldProps('lastName')}
                            error={Boolean(touched.lastName && errors.lastName)}
                            helperText={touched.lastName && errors.lastName}
                        />
                    </Stack>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Email address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        edge="end"
                                        onClick={() => setShowPassword((prev) => !prev)}
                                    >
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Register
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    )
}

export default RegisterForm
