import { call, put, takeLatest } from '@redux-saga/core/effects'
// Third-party
import { DateTime } from 'luxon'
// Internal
import { AsyncReturnType } from 'assets/@types'
import { HubMetadataConfig } from 'assets/@types/sites'
import { configService, reportsService } from 'assets/services'
import { get, set } from '../slices/config'
import { S3Object } from 'assets/@types/admin'
import { FileTree } from 'assets/@types/config'

function* getConfig() {
    // TODO: Create a ReturnType to omit void in union type
    const config: AsyncReturnType<typeof configService['fetchTransferPortalConfig']> = yield call(
        configService.fetchTransferPortalConfig,
    )

    const reports: { Contents: S3Object[] } = yield call(reportsService.fetchReports)

    let keys: Set<string> = new Set()
    let fileTree: FileTree = {}

    if (reports) {
        fileTree = reports.Contents.reduce((acc, report) => {
            const [, key, filename] = report.Key.split('/')
            if (!!key) {
                keys.add(key)

                const [dateString] = filename.split('-report.pdf')
                const date = DateTime.fromFormat(dateString, 'M-d-yyyyh:m:ssa')

                if (!date.isValid) return acc

                const year = date.year
                const month = date.month

                if (!acc[year]) {
                    Object.assign(acc, { [year]: { [month]: { [key]: report } } })
                } else {
                    const existingReports = (acc[year] as FileTree)[month]
                    Object.assign(acc[year], { [month]: { [key]: report, ...existingReports } })
                }
            }

            return acc
        }, fileTree)
    }

    yield put(
        set({
            reports: {
                keys: Array.from(keys),
                fileTree,
            },
            transferPortal: { fetched: true, config: config as HubMetadataConfig },
        }),
    )
}

function* searchSaga() {
    yield takeLatest(get.type, getConfig)
}

export default searchSaga
