// Third-party
import axios from 'axios'
// Internal
import { getBaseUrl, ProxyUrl } from 'assets/utils/api-data'
import { AuthorizedUser } from 'assets/@types/portal'

type PortalService = {
    fetchAuthorizedUsers: () => Promise<AuthorizedUser[] | void>
    updateAuthorizedUser: ({ user }: { user: AuthorizedUser }) => Promise<AuthorizedUser | void>
}

const portalService = () => {
    const BASE_URL = `${ProxyUrl()}${getBaseUrl()}portal`

    const fetchAuthorizedUsers: PortalService['fetchAuthorizedUsers'] = async () => {
        const url = `${BASE_URL}/authorized-users`

        const result = await axios
            .get<[AuthorizedUser[]]>(url)
            .then(({ data }) => {
                const [authorizedUsers] = data

                return authorizedUsers
            })
            .catch((error) => console.error(`usersService.fetchAuthorizedUsers error ${error}`))

        return result
    }

    const updateAuthorizedUser: PortalService['updateAuthorizedUser'] = async ({ user }) => {
        const url = `${BASE_URL}/authorized-users`

        const result = await axios
            .put(url, user)
            .then(({ data }) => data)
            .catch((error) => console.error(`usersService.updateAuthorizedUser error ${error}`))

        return result
    }

    return {
        fetchAuthorizedUsers,
        updateAuthorizedUser,
    }
}

export default portalService()
