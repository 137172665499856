import { useContext } from 'react'
// Internal
import { AuthContext } from 'contexts/aws-cognito-context'

const useAuth = () => {
    const context = useContext(AuthContext)

    if (!context) throw new Error('Auth context must be use inside AuthProvider')

    return context
}

export default useAuth
