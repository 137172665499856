import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
// Internal
import { ConfigState } from 'assets/@types/config'

const initialState: ConfigState = {
    reports: {
        keys: [],
        fileTree: {},
    },
    transferPortal: {
        config: {},
        fetched: false,
    },
}

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        set: (state, { payload }: PayloadAction<ConfigState>) => ({
            ...state,
            ...payload,
        }),
    },
})

export const get = createAction('config/GET_CONFIG')

// Action creators are generated for each case reducer function
export const { set } = configSlice.actions

export default configSlice.reducer
