import { FunctionComponent } from 'react'
// MUI
import { Box, BoxProps } from '@mui/material'
// Third-party
import { motion } from 'framer-motion'
// Internal
import { varSmallClick, varMediumClick } from './variants'

interface ButtonAnimateProps extends BoxProps {
    mediumClick?: boolean
}

const ButtonAnimate: FunctionComponent<ButtonAnimateProps> = ({
    mediumClick = false,
    children,
    sx,
    ...other
}): JSX.Element => (
    <Box
        component={motion.div}
        whileTap="tap"
        whileHover="hover"
        variants={mediumClick ? varMediumClick : varSmallClick}
        sx={{ display: 'inline-flex', ...sx }}
        {...other}
    >
        {children}
    </Box>
)

export default ButtonAnimate
