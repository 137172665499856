import globals from './globals'

export function getBaseUrl() {
    return globals.getBaseApiUrl()
}

export function HubsUrl() {
    return `${getBaseUrl()}hub/get-all-hubs-with-metadata`
}

export function AddHubUrl() {
    return `${getBaseUrl()}hub`
}

export function UpdateHubUrl() {
    return `${getBaseUrl()}hub/update-hub`
}

export function UpdateHubStatusUrl() {
    return `${getBaseUrl()}hub/update-hub-status`
}

export function AddPodUrl() {
    return `${getBaseUrl()}pod`
}

export function UpdatePodUrl() {
    return `${getBaseUrl()}pod/update-pod`
}

export function UpdatePodStatusUrl() {
    return `${getBaseUrl()}pod/update-pod-status`
}

export function PodsUrl(key: string) {
    return `${getBaseUrl()}pod/get-pods-with-metadata?hubID=${key}`
}

export function ProxyUrl() {
    return ''
}

export function TransferUserRegionUrl() {
    return `${UsersUrl()}/update-region`
}

export function UsersUrl() {
    return `${getBaseUrl()}users`
}

export function UpdateUserUrl() {
    return `${UsersUrl()}/update-user`
}

export function UpdateUserStatusUrl() {
    return `${UsersUrl()}/update-user-status`
}

export function UpdateUserTypeUrl() {
    return `${UsersUrl()}/update-user-type`
}

export function ConfigUrl() {
    return `${getBaseUrl()}config`
}

export function TransferPortalConfigUrl() {
    return `${ConfigUrl()}/transfer-portal`
}

export function ReportsUrl() {
    return `${getBaseUrl()}reports`
}

export function StripeClientSecretUrl() {
    return `${getBaseUrl()}stripe/payment-intent`
}

export function StripePaymentSubscriptionUrl() {
    return `${getBaseUrl()}stripe/subscription`
}
