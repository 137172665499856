import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
// Internal
import { Hub, SitesState } from 'assets/@types/sites'
import { quickFilter } from 'assets/utils/filter'

const initialState: SitesState<Hub> = {
    filters: {},
    fetching: false,
    ref: null,
    selected: null,
    sites: null,
}

export const hubsSlice = createSlice({
    name: 'hubs',
    initialState,
    reducers: {
        filter: (state, { payload }: PayloadAction<Record<string, any>>) => {
            const { filters, ref } = state

            const filtersRef = JSON.parse(JSON.stringify(filters))

            Object.assign(filtersRef, payload)

            const filtered =
                ref?.filter((site) => {
                    const keys = Object.keys(filtersRef)

                    if (!keys.length) return true

                    return keys.every((key) => {
                        if (filtersRef[key] === null) return true

                        return (site as any)[key] === filtersRef[key]
                    })
                }) || []

            return {
                ...state,
                filters: filtersRef,
                ref,
                sites: filtered,
            }
        },
        search: (state, { payload }: PayloadAction<string>) => {
            const { sites, ...rest } = state

            return {
                sites: payload ? quickFilter(payload, sites || []) : rest.ref,
                ...rest,
            }
        },
        select: (state, { payload }: PayloadAction<any>) => {
            state.selected = payload
        },
        set: (state, { payload }: PayloadAction<Partial<SitesState<Hub>>>) => ({
            ...state,
            ...payload,
        }),
        sort: (state, { payload }: PayloadAction<number>) => {
            console.log('payload: ', payload)
            const { sites } = state

            const sitesRef: Hub[] = JSON.parse(JSON.stringify(sites))

            const sorted = sitesRef?.sort(({ name: aName }, { name: bName }) =>
                payload > 0
                    ? bName.localeCompare(aName, 'en', {
                          sensitivity: 'base',
                      })
                    : aName.localeCompare(bName, 'en', {
                          sensitivity: 'base',
                      }),
            )

            return {
                ...state,
                sites: sorted,
            }
        },
        update: (state, { payload }: PayloadAction<any>) => {
            const { sites, ref } = state

            let updatedHub = payload.hub || payload

            // !Looks greedy, consider a less costly approach to updating
            const [updatedRef, updatedSites] = [
                JSON.parse(JSON.stringify(ref)) as Hub[],
                JSON.parse(JSON.stringify(sites)) as Hub[],
            ].map(
                (list) =>
                    list
                        .map(({ id, ...hub }) => {
                            if (id === updatedHub.id) {
                                updatedHub = Object.assign(hub, updatedHub)
                                return updatedHub
                            }

                            return { id, ...hub }
                        })
                        .filter(Boolean) as Hub[],
            )

            return {
                ...state,
                ref: updatedRef,
                selected: updatedHub,
                sites: updatedSites,
            }
        },
    },
})

const addHub = createAction('hubs/ADD_HUB', ({ hub }: { hub: Hub }, onUpdate: () => void) => ({
    payload: {
        onUpdate,
        hub,
    },
}))

const getHubs = createAction('hubs/GET_HUBS')

const updateHub = createAction(
    'hubs/UPDATE_HUB',
    ({ hub }: { hub: Hub }, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            hub,
        },
    }),
)

const updateHubStatus = createAction(
    'hubs/UPDATE_HUB_STATUS',
    (hub: Pick<Hub, 'id' | 'status'>, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            hub,
        },
    }),
)

const { filter, search, select, set, sort, update } = hubsSlice.actions

export default hubsSlice.reducer

export { addHub, getHubs, filter, search, select, set, sort, update, updateHub, updateHubStatus }
