import { call, put, select, takeLatest } from '@redux-saga/core/effects'
// Internal
import {
    addHub,
    getHubs,
    set,
    update as updateState,
    updateHub,
    updateHubStatus,
} from '../slices/hubs'
import { hubsService } from 'assets/services'
import { Region } from 'assets/@types'
import { Hub } from 'assets/@types/sites'
import { getRegion, set as setRegion } from 'store/slices/regions'

function* add({ payload }: ReturnType<typeof addHub>) {
    yield put(set({ fetching: true }))

    const { hub, onUpdate } = payload

    const newHub = JSON.parse(JSON.stringify(hub))

    yield call(hubsService.createHub, { hub: newHub })

    yield call(onUpdate)
}

function* get() {
    yield put(set({ fetching: true, sites: null, selected: null }))

    let hubs: Hub[] = yield call(hubsService.fetchHubs)

    hubs = hubs.map((hub) => {
        if (hub.megaHubID) {
            hub.megaHub = +hub.megaHubID < 0
        }

        return hub
    })

    const { selected, user }: Region = yield select(getRegion)

    if (user) {
        if (!selected) {
            let hubToSelect: Hub | null = null
            const regions =
                hubs.filter(({ id, megaHubID, ...hub }) => {
                    if (megaHubID) {
                        if (!hubToSelect && id === user.region) {
                            hubToSelect = {
                                id,
                                megaHubID,
                                ...hub,
                            }
                        }

                        return +megaHubID < 0
                    }

                    return false
                }) || null

            yield put(
                setRegion({
                    regions,
                    selected: hubToSelect,
                    user,
                }),
            )

            hubs = hubs.filter(
                ({ id, megaHubID }) => megaHubID == hubToSelect?.id || id === hubToSelect?.id,
            )
        } else {
            const newRegions = hubs.filter(({ id, megaHubID, ...hub }) =>
                megaHubID ? +megaHubID < 0 : false,
            )

            yield put(
                setRegion({
                    regions: newRegions,
                    selected,
                    user,
                }),
            )

            // Show mega hub and associated hubs only
            hubs = hubs.filter(
                ({ id, megaHubID }) => megaHubID == selected.id || id === selected.id,
            )
        }
    }

    yield put(set({ fetching: false, ref: hubs, sites: hubs, selected: null }))
}

function* update({ payload }: ReturnType<typeof updateHub>) {
    yield put(set({ fetching: true }))

    const { hub, onUpdate } = payload
    yield call(hubsService.updateHub, { hub })

    yield put(set({ fetching: false }))
    yield put(getHubs)
    yield call(onUpdate)
}

function* updateStatus({ payload }: ReturnType<typeof updateHubStatus>) {
    yield put(set({ fetching: true }))

    const { hub, onUpdate } = payload
    yield call(hubsService.updateHubStatus, hub)

    yield put(updateState(hub))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* hubsSaga() {
    yield takeLatest(addHub.type, add)
    yield takeLatest(getHubs.type, get)
    yield takeLatest(updateHub.type, update)
    yield takeLatest(updateHubStatus.type, updateStatus)
}

export default hubsSaga
