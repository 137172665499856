import { FunctionComponent, useEffect } from 'react'
// MUI
import { Box, Stack, Container, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// Internal
import useAuth from 'assets/hooks/use-auth'
import { LoginForm } from 'components/authentication-forms'
import Page from 'components/page'

import CityServeLogo from 'assets/images/cityserve-logo-white.png'
import LoginBackground from 'assets/images/login_bg.png'

const RootStyle = styled(Page)(({ theme }) => ({
    backgroundImage: `url(${LoginBackground})`,
    backgroundPosition: 'bottom',
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}))

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(12, 0),
}))

const Login: FunctionComponent = (): JSX.Element => {
    // Hooks
    const { getAuthorizedUsers } = useAuth()

    useEffect(() => {
        getAuthorizedUsers() // Get new list each time login page is loaded
    }, [])

    return (
        <>
            {/* <Alert severity="info" sx={{ mb: 3 }}>
            Use email : <strong>demo@minimals.cc</strong> / password :
            <strong>&nbsp;demo1234</strong>
        </Alert> */}

            <RootStyle title="Login to CityServe">
                {/* <AuthLayout>
                Don’t have an account? &nbsp;
                <Link
                    underline="none"
                    variant="subtitle2"
                    component={RouterLink}
                    to={PATH_AUTH.register}
                >
                    Get started
                </Link>
            </AuthLayout> */}
                <Container maxWidth="sm">
                    <ContentStyle>
                        <Stack
                            alignItems="center"
                            direction="row"
                            justifyContent="center"
                            sx={{ mb: 8 }}
                        >
                            <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                                <img alt="Logo" width="30%" src={CityServeLogo} />
                            </Box>
                        </Stack>
                        <LoginForm />
                        {/* <MuiHidden width="smUp">
                        <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                            Don’t have an account?&nbsp;
                            <Link
                                variant="subtitle2"
                                component={RouterLink}
                                to={PATH_AUTH.register}
                            >
                                Get started
                            </Link>
                        </Typography>
                    </MuiHidden> */}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </>
    )
}

export default Login
