import { call, put, takeLatest } from '@redux-saga/core/effects'
// Internal
import {
    createPaymentSubscription,
    getClientSecret,
    clearClientSecret,
    set,
    getSubscriptionData,
    updatePaymentSubscription,
} from '../slices/subscriptions'
import { stripeSubscriptionsService } from 'assets/services'

function* getStripeClientSecret({ payload }: ReturnType<typeof getClientSecret>) {
    yield put(set({ loading: true, clientSecret: null }))

    const { amount } = payload

    let { secret } = yield call(stripeSubscriptionsService.generateClientSecret, amount)

    yield put(set({ loading: false, clientSecret: secret }))
}

function* clearStripeClientSecret() {
    yield put(set({ loading: false, clientSecret: null }))
}

function* requestPaymentSubscription({ payload }: ReturnType<typeof createPaymentSubscription>) {
    yield put(set({ loading: true }))

    const { subscriptionInfo } = payload

    yield call(stripeSubscriptionsService.createSubscription, subscriptionInfo)
    yield put(getSubscriptionData({ email: subscriptionInfo.email }))

    yield put(set({ loading: false, clientSecret: null }))
}

function* modifyPaymentSubscription({ payload }: ReturnType<typeof updatePaymentSubscription>) {
    yield put(set({ loading: true }))
    const { subscriptionInfo, onUpdate } = payload

    yield call(stripeSubscriptionsService.updateSubscription, subscriptionInfo)
    yield put(getSubscriptionData({ email: subscriptionInfo.email }))

    yield put(set({ loading: false, clientSecret: null }))
}

function* getHubSubscriptionData({ payload }: ReturnType<typeof getSubscriptionData>) {
    yield put(set({ loading: true, clientSecret: null }))

    const { email } = payload
    console.log(email)
    let { subscription } = yield call(stripeSubscriptionsService.getSubscriptionData, email)

    yield put(set({ loading: false, hubSubscriptionData: subscription }))
}

function* subscriptionsSaga() {
    yield takeLatest(getClientSecret.type, getStripeClientSecret)
    yield takeLatest(clearClientSecret.type, clearStripeClientSecret)
    yield takeLatest(createPaymentSubscription.type, requestPaymentSubscription)
    yield takeLatest(updatePaymentSubscription.type, modifyPaymentSubscription)
    yield takeLatest(getSubscriptionData.type, getHubSubscriptionData)
}

export default subscriptionsSaga
