import { createSlice } from '@reduxjs/toolkit'
// Internal
import { Site } from 'assets/@types/sites'

const initialState: { site: Site | null; visible: boolean } = {
    site: null,
    visible: false,
}

export const toolboxSlice = createSlice({
    name: 'toolbox',
    initialState,
    reducers: {
        reset: () => initialState,
        set: (state, { payload }) => {
            return {
                ...payload,
            }
        },
    },
})

export const { reset, set } = toolboxSlice.actions

export default toolboxSlice.reducer
