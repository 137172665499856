import { FunctionComponent } from 'react'
// MUI
import { Box, BoxProps } from '@mui/material'
// Third-party
import { motion } from 'framer-motion'
// Internal
import { varWrapEnter } from './variants'

interface MotionContainerProps extends BoxProps {
    initial?: boolean | string
    open?: boolean
}

const MotionContainer: FunctionComponent<MotionContainerProps> = ({
    open,
    children,
    ...other
}): JSX.Element => (
    <Box
        component={motion.div}
        initial={false}
        animate={open ? 'animate' : 'exit'}
        variants={varWrapEnter}
        {...other}
    >
        {children}
    </Box>
)

export default MotionContainer
