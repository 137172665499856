import { FunctionComponent, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
// hooks
import useAuth from 'assets/hooks/use-auth'
import Login from 'pages/authentication/login'

const AuthGuard: FunctionComponent = ({ children }): JSX.Element => {
    // State hooks
    const [requestedLocation, setRequestedLocation] = useState<string | null>(null)
    // Hooks
    const { isAuthenticated } = useAuth()
    const { pathname } = useLocation()

    if (!isAuthenticated) {
        if (pathname !== requestedLocation) {
            setRequestedLocation(pathname)
        }
        return <Login />
    }

    if (requestedLocation && pathname !== requestedLocation) {
        setRequestedLocation(null)
        return <Navigate to={requestedLocation} />
    }

    return <>{children}</>
}

export default AuthGuard
