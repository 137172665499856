// Third-party
import axios from 'axios'
// Internal
import { Pod, PodInfo } from 'assets/@types/sites'
import { User } from 'assets/@types/users'
import {
    AddPodUrl,
    getBaseUrl,
    PodsUrl,
    ProxyUrl,
    UpdatePodStatusUrl,
    UpdatePodUrl,
} from 'assets/utils/api-data'
import globals from 'assets/utils/globals'
import { generateQueryString } from 'assets/utils/query-string'

type PodsServiceParams = {
    hubID: number
    pod: Pod
    podID: number
}

type PodsService = {
    createPod: ({ pod }: Pick<PodsServiceParams, 'pod'>) => Promise<Pod | void>
    fetchInfo: ({ pod }: Pick<PodsServiceParams, 'pod'>) => Promise<PodInfo>
    fetchPods: ({ hubID }: Pick<PodsServiceParams, 'hubID'>) => Promise<Pod[] | void>
    fetchUsers: ({ podID }: Pick<PodsServiceParams, 'podID'>) => Promise<User[] | void>
    transferPod: ({ id, hubID }: Pick<Pod, 'id' | 'hubID'>) => Promise<Pod | void>
    updatePod: ({ pod }: Pick<PodsServiceParams, 'pod'>) => Promise<Pod | void>
    updatePodStatus: ({ id, status }: Pick<Pod, 'id' | 'status'>) => Promise<Pod | void>
}

const podsService = (): PodsService => {
    const createPod: PodsService['createPod'] = async ({ pod }) => {
        const url = `${ProxyUrl()}${AddPodUrl()}`

        const result = await axios
            .post<Pod>(url, pod)
            .then(({ data }) => data)
            .catch((error) => console.error(`podsService.createPod error ${error}`))

        return result
    }

    const fetchInfo: PodsService['fetchInfo'] = async ({ pod: { id } }) => {
        const url = `${ProxyUrl()}${getBaseUrl()}portal/pods/${id}/info`

        const result = await axios
            .get<[[PodInfo]]>(url)
            .then(({ data }) => {
                const [result] = data
                return result[0]
            })
            .catch((error) => {
                console.error(`podsService.fetchInfo error ${error}`)
                return Object.assign({} as PodInfo)
            })

        return result
    }

    const fetchPods: PodsService['fetchPods'] = async ({ hubID }) => {
        const url = `${ProxyUrl()}${PodsUrl(`${hubID}`)}`

        const result = await axios
            .get<Pod[]>(url)
            .then(({ data }) =>
                data.map(({ admins, heroes, ...hub }) => ({
                    admins: typeof admins === 'string' ? JSON.parse(admins) : admins,
                    heroes: typeof heroes === 'string' ? JSON.parse(heroes) : heroes,
                    ...hub,
                })),
            )
            .catch((error) => console.error(`podsService.fetchPods error ${error}`))

        return result
    }

    const fetchUsers: PodsService['fetchUsers'] = async ({ podID }) => {
        const url = `${ProxyUrl()}${globals.getBaseApiUrl()}pod/get-users${generateQueryString({
            podID,
            portal: true,
        })}`

        const results = await axios
            .get<{ users: User[] }>(url)
            .then(({ data: { users } }) => users)
            .catch((error) => console.error(`podsService.fetchUsers error ${error}`))

        return results
    }

    const updatePod: PodsService['updatePod'] = async ({ pod }) => {
        const url = `${ProxyUrl()}${UpdatePodUrl()}`

        const result = await axios
            .put<Pod>(url, { podID: pod.id, ...pod })
            .then(({ data }) => data)
            .catch((error) => console.error(`podsService.updatePod error ${error}`))

        return result
    }

    const transferPod: PodsService['transferPod'] = async ({ id, hubID }) => {
        const url = `${ProxyUrl()}${UpdatePodUrl()}`

        const result = await axios
            .put<Pod>(url, { id, hubID })
            .then(({ data }) => data)
            .catch((error) => console.error(`podsService.transferPod error ${error}`))

        return result
    }

    const updatePodStatus: PodsService['updatePodStatus'] = async ({ id, status }) => {
        const url = `${ProxyUrl()}${UpdatePodStatusUrl()}`

        const result = await axios
            .put<Pod>(url, { podID: id, status })
            .then(({ data }) => data)
            .catch((error) => console.error(`podsService.updatePodStatus error ${error}`))

        return result
    }

    return {
        createPod,
        fetchInfo,
        fetchPods,
        fetchUsers,
        transferPod,
        updatePod,
        updatePodStatus,
    }
}

export default podsService()
