import { forwardRef } from 'react'
// MUI
import { IconButton, IconButtonProps } from '@mui/material'
// Internal
import { ButtonAnimate } from '../animate'

const MuiIconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
    ({ children, ...other }, ref) => (
        <ButtonAnimate>
            <IconButton ref={ref} {...other}>
                {children}
            </IconButton>
        </ButtonAnimate>
    ),
)

export default MuiIconButton
