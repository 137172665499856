import { FunctionComponent } from 'react'
import { makeStyles } from '@mui/styles'
// Internal
import Font from './Wellingtons-Medium.woff2'

const wellingtonsMedium: any = {
    fontDisplay: 'swap',
    fontFamily: 'Wellingtons-Medium',
    fontStyle: 'normal',
    fontWeight: 500,
    src: [`local('Wellingtons')`, `local('Wellingtons-Medium')`, `url('.${Font}') format('woff2')`],
}

const useStyles = makeStyles({
    '@global': {
        '@font-face': wellingtonsMedium,
    },
})

const WellingtonsMedium: FunctionComponent = (): JSX.Element => {
    useStyles()

    return <></>
}

export { wellingtonsMedium, WellingtonsMedium }
