import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
// Internal
import { User, UsersState } from 'assets/@types/users'
import { quickFilter } from 'assets/utils/filter'

const initialState: UsersState = {
    filters: {},
    fetching: false,
    ref: null,
    selected: null,
    users: null,
    profile: null,
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        filter: (state, { payload }: PayloadAction<Record<string, any>>) => {
            const { filters, ref } = state

            const filtersRef = JSON.parse(JSON.stringify(filters))

            Object.assign(filtersRef, payload)

            const filtered =
                ref?.filter((user) => {
                    const keys = Object.keys(filtersRef)

                    if (!keys.length) return true

                    return keys.every((key) => {
                        if (!filtersRef[key]) return true

                        return (user as any)[key] === filtersRef[key]
                    })
                }) || []

            return {
                ...state,
                filters: filtersRef,
                ref,
                users: filtered,
            }
        },
        search: (state, { payload }: PayloadAction<string>) => {
            const { users, ...rest } = state

            return {
                users: payload ? quickFilter(payload, users || []) : rest.ref,
                ...rest,
            }
        },
        select: (state, { payload }: PayloadAction<User | null>) => {
            state.selected = payload
        },
        set: (state, { payload }: PayloadAction<Partial<UsersState>>) => ({
            ...state,
            ...payload,
        }),
        sort: (state, { payload }: PayloadAction<number>) => {
            console.log('payload: ', payload)
            const { users } = state

            const usersRef: User[] = JSON.parse(JSON.stringify(users))

            const sorted = usersRef?.sort(
                (
                    { firstName: aFName, lastName: aLName },
                    { firstName: bFName, lastName: bLName },
                ) =>
                    payload > 0
                        ? `${bFName} ${bLName}`.localeCompare(`${aFName} ${aLName}`, 'en', {
                              sensitivity: 'base',
                          })
                        : `${aFName} ${aLName}`.localeCompare(`${bFName} ${bLName}`, 'en', {
                              sensitivity: 'base',
                          }),
            )

            return {
                ...state,
                users: sorted,
            }
        },
        update: (
            state,
            { payload }: PayloadAction<any>, // !Need to figure out a funky type to handle partial objects
        ) => {
            const { users, ref } = state

            let updatedUser = payload.user || payload

            // !Looks greedy, consider a less costly approach to updating
            const [updatedRef, updatedUsers] = [
                JSON.parse(JSON.stringify(ref)) as User[],
                JSON.parse(JSON.stringify(users)) as User[],
            ].map(
                (list) =>
                    list
                        .map(({ id, ...user }) => {
                            if (id === updatedUser.id) {
                                updatedUser = Object.assign(user, updatedUser)
                                return updatedUser
                            }

                            return { id, ...user }
                        })
                        .filter(Boolean) as User[],
            )

            return {
                ...state,
                ref: updatedRef,
                selected: updatedUser,
                users: updatedUsers,
            }
        },
    },
})

const addUser = createAction(
    'users/ADD_USER',
    ({ user }: { user: User }, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            user,
        },
    }),
)

const getUsers = createAction('users/GET_USERS')

const getHubUsers = createAction('users/GET_HUB_USERS', ({ hubID }: { hubID: number }) => ({
    payload: {
        hubID,
    },
}))

const getPodUsers = createAction('users/GET_POD_USERS', ({ podID }: { podID: number }) => ({
    payload: {
        podID,
    },
}))

const transferUser = createAction(
    'pods/TRANSFER_USER',
    (user: Pick<User, 'id' | 'hubID' | 'podID'>, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            user,
        },
    }),
)

const transferUserRegion = createAction(
    'pods/TRANSFER_USER_REGION',
    ({ megaHubID, user }: { megaHubID: number | string; user: User }, onUpdate: () => void) => ({
        payload: {
            megaHubID,
            onUpdate,
            user,
        },
    }),
)

const updateUser = createAction(
    'users/UPDATE_USER',
    ({ user }: { user: User }, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            user,
        },
    }),
)

const updateUserStatus = createAction(
    'users/UPDATE_USER_STATUS',
    (user: Pick<User, 'id' | 'status'>, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            user,
        },
    }),
)

const updateUserPortalStatus = createAction(
    'users/UPDATE_USER_PORTAL_STATUS',
    (user: Pick<User, 'id' | 'email' | 'megaHubID'>, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            user,
        },
    }),
)

const updateUserType = createAction(
    'users/UPDATE_USER_TYPE',
    (user: Pick<User, 'id' | 'type'>, onUpdate: () => void) => ({
        payload: {
            onUpdate,
            user,
        },
    }),
)

const getUserProfile = createAction('users/GET_PROFILE')

const clearProfile = createAction('users/CLEAR_PROFILE')

// Action creators are generated for each case reducer function
const { filter, search, select, set, sort, update } = usersSlice.actions

export default usersSlice.reducer

export {
    addUser,
    getUsers,
    getHubUsers,
    getPodUsers,
    filter,
    search,
    select,
    set,
    sort,
    transferUser,
    transferUserRegion,
    update,
    updateUser,
    updateUserStatus,
    updateUserPortalStatus,
    updateUserType,
    getUserProfile,
    clearProfile,
}
