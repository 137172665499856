import { FunctionComponent } from 'react'
import { Navigate } from 'react-router-dom'
// Internal
import useAuth from 'assets/hooks/use-auth'
import { PATH_DASHBOARD } from 'routes/paths'

const GuestGuard: FunctionComponent = ({ children }): JSX.Element => {
    // Hooks
    const { isAuthenticated } = useAuth()

    if (isAuthenticated) {
        return <Navigate to={PATH_DASHBOARD.root} />
    }

    return <>{children}</>
}

export default GuestGuard
