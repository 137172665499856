import { FunctionComponent } from 'react'
import { makeStyles } from '@mui/styles'
// Internal
import Font from './Wellingtons-Regular.woff2'

const wellingtonsRegular: any = {
    fontDisplay: 'swap',
    fontFamily: 'Wellingtons-Regular',
    fontStyle: 'normal',
    fontWeight: 400,
    src: [
        `local('Wellingtons')`,
        `local('Wellingtons-Regular')`,
        `url('.${Font}') format('woff2')`,
    ],
}

const useStyles = makeStyles({
    '@global': {
        '@font-face': wellingtonsRegular,
    },
})

const WellingtonsRegular: FunctionComponent = (): JSX.Element => {
    useStyles()

    return <></>
}

export { wellingtonsRegular, WellingtonsRegular }
