import { FunctionComponent, useEffect } from 'react'
// Internal
import Router from 'routes'
import ThemeConfig from 'assets/theme'
import GlobalStyles from 'assets/theme/globalStyles'
import useAuth from 'assets/hooks/use-auth'
// Components
import RtlLayout from 'components/rtl-layout'
import ScrollToTop from 'components/scroll-to-top'
import NotistackProvider from 'components/notistack-provider'
import ThemePrimaryColor from 'components/theme-primary-color'
import ThemeLocalization from 'components/theme-localization'
import LoadingScreen, { ProgressBarStyle } from 'components/loading-screen'
import { useDispatch } from 'store'
import { get as getConfig } from 'store/slices/config'

const App: FunctionComponent = (): JSX.Element => {
    // State hooks
    const dispatch = useDispatch()
    // Hooks
    const { isInitialized } = useAuth()

    useEffect(() => {
        dispatch(getConfig())
    }, [])

    return (
        <ThemeConfig>
            <ThemePrimaryColor>
                <ThemeLocalization>
                    <RtlLayout>
                        <NotistackProvider>
                            <GlobalStyles />
                            <ProgressBarStyle />
                            <ScrollToTop />
                            {isInitialized ? <Router /> : <LoadingScreen />}
                        </NotistackProvider>
                    </RtlLayout>
                </ThemeLocalization>
            </ThemePrimaryColor>
        </ThemeConfig>
    )
}

export default App
