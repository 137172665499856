import { FunctionComponent } from 'react'
import { Icon } from '@iconify/react'
import menu2Fill from '@iconify/icons-eva/menu-2-fill'
// material
import { alpha, styled } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material'
// hooks
import { useCollapseDrawer } from 'assets/hooks'
// components
import { MuiHidden } from 'components/@material-extend'
import AccountPopover from './account-popover'
import RegionPopover from './region-popover'

const DRAWER_WIDTH = 280
const COLLAPSE_WIDTH = 102

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: alpha(theme.palette.background.default, 0.72),
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
    },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5),
    },
}))

type DashboardNavbarProps = {
    onOpenSidebar: VoidFunction
}

const DashboardNavbar: FunctionComponent<DashboardNavbarProps> = ({
    onOpenSidebar,
}): JSX.Element => {
    // Hooks
    const { isCollapse } = useCollapseDrawer()

    return (
        <RootStyle
            sx={{
                ...(isCollapse && {
                    width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` },
                }),
            }}
        >
            <ToolbarStyle>
                <MuiHidden width="lgUp">
                    <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                        <Icon icon={menu2Fill} />
                    </IconButton>
                </MuiHidden>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <RegionPopover />
                    <AccountPopover />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    )
}

export default DashboardNavbar
