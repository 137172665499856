import { FunctionComponent, ReactNode } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// MUI
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
// Internal
import Logo from 'components/logo'
import { MuiHidden } from 'components/@material-extend'

const Header = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    padding: theme.spacing(3),
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-start',
        padding: theme.spacing(7, 5, 0, 7),
    },
}))

type AuthLayoutProps = {
    children: ReactNode
}

const AuthLayout: FunctionComponent<AuthLayoutProps> = ({ children }): JSX.Element => (
    <Header>
        <RouterLink to="/">
            <Logo />
        </RouterLink>
        <MuiHidden width="smDown">
            <Typography
                variant="body2"
                sx={{
                    mt: { md: -2 },
                }}
            >
                {children}
            </Typography>
        </MuiHidden>
    </Header>
)

export default AuthLayout
