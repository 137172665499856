import { all, call, spawn } from '@redux-saga/core/effects'
// Internal
import configSaga from './config-saga'
import hubsSaga from './hubs-saga'
import podsSaga from './pods-saga'
import usersSaga from './users-saga'
import stripeSubscriptionsSaga from './subscriptions-saga'

function* rootSaga() {
    const sagas = [configSaga, hubsSaga, podsSaga, usersSaga, stripeSubscriptionsSaga].map((saga) =>
        spawn(function* () {
            while (true) {
                try {
                    yield call(saga)
                    break
                } catch (error) {
                    console.error(`rootSaga error: ${error}`)
                }
            }
        }),
    )

    yield all(sagas)
}

export default rootSaga
