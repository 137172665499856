// Third-party
import axios from 'axios'
// Internal
import { StripeClientSecretUrl, StripePaymentSubscriptionUrl } from 'assets/utils/api-data'
import { HubSubscriptionData, StripeSubscription } from 'assets/@types/subscriptions'

type StripeSubscriptionsService = {
    generateClientSecret: (amount: number) => Promise<{ secret: string } | void>
    createSubscription: (subscriptionInfo: StripeSubscription) => Promise<void>
    updateSubscription: (subscriptionInfo: StripeSubscription) => Promise<void>
    getSubscriptionData: (email: string) => Promise<HubSubscriptionData | void>
}

const StripeSubscriptionsService = () => {
    const generateClientSecret: StripeSubscriptionsService['generateClientSecret'] = async (
        amount,
    ) => {
        const result = await axios
            .post<{ secret: string }>(StripeClientSecretUrl(), { amount: amount })
            .then((response) => {
                return response.data
            })
            .catch((error) =>
                console.error(`StripeSubscriptionsService.generateClientSecret error ${error}`),
            )

        return result
    }

    const createSubscription: StripeSubscriptionsService['createSubscription'] = async (
        subscriptionInfo,
    ) => {
        const result = await axios
            .post(StripePaymentSubscriptionUrl(), subscriptionInfo)
            .then((response) => {
                return response.data
            })
            .catch((error) =>
                console.error(`StripeSubscriptionsService.createSubscription error ${error}`),
            )

        return result
    }

    const updateSubscription: StripeSubscriptionsService['updateSubscription'] = async (
        subscriptionInfo,
    ) => {
        const result = await axios
            .put(StripePaymentSubscriptionUrl(), subscriptionInfo)
            .then((response) => {
                return response.data
            })
            .catch((error) =>
                console.error(`StripeSubscriptionsService.updateSubscription error ${error}`),
            )

        return result
    }

    const getSubscriptionData: StripeSubscriptionsService['getSubscriptionData'] = async (
        email,
    ) => {
        const result = await axios
            .get<HubSubscriptionData>(`${StripePaymentSubscriptionUrl()}?email=${email}`)
            .then((response) => {
                console.log(response.data)
                return response.data
            })
            .catch((error) =>
                console.error(`StripeSubscriptionsService.getSubscriptionData error ${error}`),
            )

        return result
    }

    return {
        generateClientSecret,
        createSubscription,
        updateSubscription,
        getSubscriptionData,
    }
}

export default StripeSubscriptionsService()
