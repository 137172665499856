import { createDraftSafeSelector, createSlice } from '@reduxjs/toolkit'
// Internal
import { RegionsState } from 'assets/@types'
import { RootState } from 'store'

const initialState: RegionsState = {
    regions: null,
    selected: null,
    user: null,
}

export const regionsSlice = createSlice({
    name: 'regions',
    initialState,
    reducers: {
        reset: () => initialState,
        set: (state, { payload }: { payload: RegionsState }) => ({
            ...state,
            ...payload,
        }),
    },
})

export const { reset, set } = regionsSlice.actions

export default regionsSlice.reducer

const selectSelf = ({ regions }: RootState) => regions

export const getRegion = createDraftSafeSelector(selectSelf, (region) => region)
