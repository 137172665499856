import Package from '../../../package.json'

interface Environment {
    CognitoClientId: string
    CognitoPoolId: string
    HostName: string
    LambdaEndpoint: string
    Region: string
    BucketName: string
    IdentityPoolId: string
    StripeKey: string
}

const DevEnv = {
    CognitoClientId: '1gatp25r3f7a6vphhj8ufrf3ti',
    CognitoPoolId: 'us-east-1_yvhlokHf6',
    HostName: 'https://v8vipgb1lb.execute-api.us-east-1.amazonaws.com/',
    LambdaEndpoint: 'https://v8vipgb1lb.execute-api.us-east-1.amazonaws.com/dev/',
    Region: 'us-east-1',
    BucketName: '',
    IdentityPoolId: 'us-east-1:310198696605:userpool/us-east-1_yvhlokHf6',
    StripeKey: 'pk_test_wURQQ5fBeUNIIJR446G4muMZ',
}

const ProdEnv = {
    CognitoClientId: '71naei6nku53urtq47p493q88m',
    CognitoPoolId: 'us-east-1_d1ApD67GC',
    HostName: 'https://v8vipgb1lb.execute-api.us-east-1.amazonaws.com/',
    LambdaEndpoint: 'https://v8vipgb1lb.execute-api.us-east-1.amazonaws.com/prod/',
    Region: 'us-east-1',
    BucketName: '',
    IdentityPoolId: 'us-east-1:310198696605:userpool/us-east-1_d1ApD67GC',
    StripeKey: 'pk_live_KzkN1XJVuSNYUKrRoAMKNw1A00ReNChfI7',
}

let CurrentEnvironment: Environment

const Globals = {
    // CONSTANTS
    APP_VERSION: `v${Package.version}`,
    DefaultPalette: {
        MainUIColor: '#439aa3',
        SecondaryUIColor: '#1e546d',
        LightTextColor: '#ffffff',
        DarkTextColor: '#333333',
    },

    // METHODS
    getApiHostName: () => CurrentEnvironment.HostName,
    getBaseApiUrl: () => CurrentEnvironment.LambdaEndpoint,
    getCognitoClient: () => CurrentEnvironment.CognitoClientId,
    getCognitoPool: () => CurrentEnvironment.CognitoPoolId,
    getCognitoRegion: () => CurrentEnvironment.Region,
    getS3BucketName: () => CurrentEnvironment.BucketName,
    getIdentityPoolId: () => CurrentEnvironment.IdentityPoolId,
    getStripeKey: () => CurrentEnvironment.StripeKey,
    setEnvironment: () => {
        const siteUrl = document.location.href

        if (
            siteUrl.includes('localhost') ||
            siteUrl.includes('dev') ||
            siteUrl.includes('127.0.0.1')
        ) {
            CurrentEnvironment = DevEnv
        } else {
            CurrentEnvironment = ProdEnv
        }
    },
}

Globals.setEnvironment()

export default Globals
