import { call, put, takeLatest } from '@redux-saga/core/effects'
// Internal
import {
    addPod,
    getPods,
    set,
    transferPod,
    update as updateState,
    updatePod,
    updatePodStatus,
} from '../slices/pods'
import { podsService } from 'assets/services'
import { Pod } from 'assets/@types/sites'

function* add({ payload }: ReturnType<typeof addPod>) {
    yield put(set({ fetching: true }))

    const { onUpdate, pod } = payload

    const newPod = JSON.parse(JSON.stringify(pod))

    yield call(podsService.createPod, { pod: newPod })

    yield call(onUpdate)
}

function* get({ payload }: ReturnType<typeof getPods>) {
    yield put(set({ fetching: true, selected: null, sites: null }))

    const { hubID } = payload

    const pods: Pod[] = yield call(podsService.fetchPods, { hubID })

    yield put(set({ fetching: false, ref: pods, selected: null, sites: pods }))
}

function* transfer({ payload }: ReturnType<typeof transferPod>) {
    yield put(set({ fetching: true }))

    const { onUpdate, pod } = payload
    yield call(podsService.updatePod, { pod })

    yield put(updateState(pod))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* update({ payload }: ReturnType<typeof updatePod>) {
    yield put(set({ fetching: true }))

    const { onUpdate, pod } = payload
    yield call(podsService.updatePod, { pod })

    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* updateStatus({ payload }: ReturnType<typeof updatePodStatus>) {
    yield put(set({ fetching: true }))

    const { onUpdate, pod } = payload
    yield call(podsService.updatePodStatus, pod)

    yield put(updateState(pod))
    yield put(set({ fetching: false }))
    yield call(onUpdate)
}

function* podsSaga() {
    yield takeLatest(addPod.type, add)
    yield takeLatest(getPods.type, get)
    yield takeLatest(transferPod.type, transfer)
    yield takeLatest(updatePod.type, update)
    yield takeLatest(updatePodStatus.type, updateStatus)
}

export default podsSaga
