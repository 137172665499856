import { combineReducers } from 'redux'
// Internal
import authReducer from './slices/auth'
import configReducer from './slices/config'
import hubsReducer from './slices/hubs'
import podsReducer from './slices/pods'
import regionsReducer from './slices/regions'
import toolboxReducer from './slices/toolbox'
import usersReducer from './slices/users'
import stripeSubscriptionsReducer from './slices/subscriptions'

const createReducer = (injectedReducers = {}) =>
    combineReducers({
        auth: authReducer,
        config: configReducer,
        hubs: hubsReducer,
        pods: podsReducer,
        regions: regionsReducer,
        toolbox: toolboxReducer,
        users: usersReducer,
        subscriptions: stripeSubscriptionsReducer,
        ...injectedReducers,
    })

export { createReducer }

export default createReducer
