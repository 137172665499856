import { ElementType, Suspense, lazy } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// Internal
import LoadingScreen from 'components/loading-screen'
import { AuthGuard, GuestGuard } from 'guards'
import { DashboardLayout, LogoOnlyLayout } from 'layouts'
import { useSelector } from 'store'
import { getRegion } from 'store/slices/regions'
import useAuth from 'assets/hooks/use-auth'

const Loadable =
    (Component: ElementType) =>
    (props: any): JSX.Element => {
        // Hooks
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { pathname } = useLocation()

        const isDashboard = pathname.includes('/dashboard')

        return (
            <Suspense
                fallback={
                    <LoadingScreen
                        sx={{
                            ...(!isDashboard && {
                                top: 0,
                                left: 0,
                                width: 1,
                                zIndex: 9999,
                                position: 'fixed',
                            }),
                        }}
                    />
                }
            >
                <Component {...props} />
            </Suspense>
        )
    }

const Router = () => {
    const { authUsers } = useAuth()
    const { user } = useSelector(getRegion)

    return useRoutes([
        {
            path: 'auth',
            children: [
                {
                    path: 'login',
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    ),
                },
                {
                    path: 'register',
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    ),
                },
                { path: 'login-unprotected', element: <Login /> },
                { path: 'register-unprotected', element: <Register /> },
                { path: 'reset-password', element: <ResetPassword /> },
            ],
        },
        {
            path: '/',
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                {
                    element: authUsers.some((obj) => obj.email === user?.email) ? (
                        <Navigate to="/hubs" />
                    ) : (
                        <Navigate to="/admin" />
                    ),
                },
                {
                    path: 'hubs',
                    children: [
                        { element: <Navigate to="/hubs/list" /> },
                        { path: 'list', element: <HubsList /> },
                        { path: ':hubID', element: <SiteForm /> },
                        { path: ':hubID/users', element: <UsersList /> },
                        { path: ':hubID/users/:userID', element: <UserUpdate type="hub" /> },
                        { path: ':hubID/pods', element: <PodsList /> },
                        { path: ':hubID/pods/:podID', element: <SiteForm /> },
                        { path: ':hubID/pods/:podID/users', element: <UsersList /> },
                        {
                            path: ':hubID/pods/users/:userID',
                            element: <UserUpdate type="pod" />,
                        },
                        { path: 'create', element: <SiteForm /> },
                    ],
                },
                {
                    path: 'users',
                    children: [
                        { element: <Navigate to="/users/list" /> },
                        { path: 'list', element: <UsersList /> },
                        { path: ':userID', element: <UserUpdate /> },
                        { path: 'update', element: <UserUpdate /> },
                    ],
                },
                {
                    path: 'admin',
                    children: [
                        {
                            element: authUsers.some((obj) => obj.email === user?.email) ? (
                                <Navigate to="/admin/reports" />
                            ) : (
                                <Navigate to="/admin/billing" />
                            ),
                        },
                        { path: 'reports', element: <Reports /> },
                        { path: 'billing', element: <Billing /> },
                    ],
                },
            ],
        },
        {
            path: '*',
            element: <LogoOnlyLayout />,
            children: [
                { path: 'maintenance', element: <Maintenance /> },
                { path: '500', element: <UhOh /> },
                { path: '404', element: <NotFound /> },
                { path: '*', element: <Navigate to="/404" replace /> },
            ],
        },
        { path: '*', element: <Navigate to="404" replace /> },
    ])
}

// Authentication
const Login = Loadable(lazy(() => import('pages/authentication/login')))
const Register = Loadable(lazy(() => import('pages/authentication/register')))
const ResetPassword = Loadable(lazy(() => import('pages/authentication/reset-password')))

// Dashboard
const HubsList = Loadable(lazy(() => import('pages/dashboard/sites/hubs')))
const PodsList = Loadable(lazy(() => import('pages/dashboard/sites/pods')))
const SiteForm = Loadable(lazy(() => import('pages/dashboard/sites/site-form')))
const UsersList = Loadable(lazy(() => import('pages/dashboard/users')))
const UserUpdate = Loadable(lazy(() => import('pages/dashboard/users/update')))
const Reports = Loadable(lazy(() => import('pages/dashboard/admin/reports')))
const Billing = Loadable(lazy(() => import('pages/dashboard/admin/billing')))

// Main
const Maintenance = Loadable(lazy(() => import('pages/maintainance')))
const NotFound = Loadable(lazy(() => import('pages/not-found')))
const UhOh = Loadable(lazy(() => import('pages/uh-oh')))

export default Router
