// Third-party
import axios from 'axios'
// Internal
import { Hub, HubInfo } from 'assets/@types/sites'
import { User } from 'assets/@types/users'
import {
    AddHubUrl,
    getBaseUrl,
    HubsUrl,
    ProxyUrl,
    UpdateHubStatusUrl,
    UpdateHubUrl,
} from 'assets/utils/api-data'
import globals from 'assets/utils/globals'
import { generateQueryString } from 'assets/utils/query-string'

type HubsServiceParams = {
    hub: Hub
    hubID: number
}

type HubsService = {
    createHub: ({ hub }: Pick<HubsServiceParams, 'hub'>) => Promise<Hub | void>
    fetchHubs: () => Promise<Hub[] | void>
    fetchInfo: ({ hubID }: Pick<HubsServiceParams, 'hubID'>) => Promise<HubInfo>
    fetchUsers: ({ hubID }: Pick<HubsServiceParams, 'hubID'>) => Promise<User[] | void>
    updateHub: ({ hub }: Pick<HubsServiceParams, 'hub'>) => Promise<Hub | void>
    updateHubStatus: ({ id, status }: Pick<Hub, 'id' | 'status'>) => Promise<Hub | void>
}

const hubsService = (): HubsService => {
    const createHub: HubsService['createHub'] = async ({ hub }) => {
        const url = `${ProxyUrl()}${AddHubUrl()}`

        const result = await axios
            .post(url, hub)
            .then(({ data }) => data)
            .catch((error) => console.error(`hubsService.createHub error ${error}`))

        return result
    }

    const fetchHubs: HubsService['fetchHubs'] = async () => {
        const queryString = generateQueryString({ portal: true })
        const url = `${ProxyUrl()}${HubsUrl()}${queryString}`

        const result = await axios
            .get<Hub[]>(url)
            .then(({ data }) =>
                data.map(({ admins, ...hub }) => ({
                    admins: typeof admins === 'string' ? JSON.parse(admins) : admins,
                    ...hub,
                })),
            )
            .catch((error) => console.error(`hubsService.fetchHubs error ${error}`))

        return result
    }

    const fetchInfo: HubsService['fetchInfo'] = async ({ hubID }) => {
        const url = `${ProxyUrl()}${getBaseUrl()}portal/hubs/${hubID}/info`

        const result = await axios
            .get<[[HubInfo]]>(url)
            .then(({ data }) => {
                const [result] = data
                return result[0]
            })
            .catch((error) => {
                console.error(`hubsService.fetchInfo error ${error}`)
                return Object.assign({} as HubInfo)
            })

        return result
    }

    const fetchUsers: HubsService['fetchUsers'] = async ({ hubID }) => {
        const queryString = generateQueryString({
            hubID,
            portal: true,
        })
        const url = `${ProxyUrl()}${globals.getBaseApiUrl()}/hub/get-users${queryString}`

        const results = await axios
            .get<{ users: User[] }>(url)
            .then(({ data: { users } }) => users)
            .catch((error) => console.error(`hubsService.fetchUsers error ${error}`))

        return results
    }

    const updateHub: HubsService['updateHub'] = async ({ hub }) => {
        const url = `${ProxyUrl()}${UpdateHubUrl()}`

        const result = await axios
            .put<Hub>(url, { hubID: hub.id, ...hub })
            .then(({ data }) => data)
            .catch((error) => console.error(`hubService.updateHub error ${error}`))

        return result
    }

    const updateHubStatus: HubsService['updateHubStatus'] = async ({ id, status }) => {
        const url = `${ProxyUrl()}${UpdateHubStatusUrl()}`

        const result = await axios
            .put<Hub>(url, { hubID: id, status })
            .then(({ data }) => data)
            .catch((error) => console.error(`hubService.updateHubStatus error ${error}`))

        return result
    }

    return {
        createHub,
        fetchHubs,
        fetchInfo,
        fetchUsers,
        updateHub,
        updateHubStatus,
    }
}

export default hubsService()
