import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'

type SliceState = {
    accessToken: string
    refreshToken: string
    username: string
    error: string
}

const initialState: SliceState = {
    accessToken: '',
    refreshToken: '',
    username: '',
    error: '',
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: () => initialState,
        set: (state, { payload }: PayloadAction<Omit<SliceState, 'error'>>) => ({
            ...state,
            ...payload,
        }),
        setError: (state, { payload }: PayloadAction<Pick<SliceState, 'error'>>) => ({
            ...state,
            ...payload,
        }),
    },
})

export const authenticateUser = createAction(
    'auth/AUTHENTICATE_USER',
    ({ email, password }: { email: string; password: string }) => ({
        payload: {
            email,
            password,
        },
    }),
)

// Action creators are generated for each case reducer function
export const { reset, set, setError } = authSlice.actions

export default authSlice.reducer
